import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import PrivacyProfile from "../../../public/images/privacy-profile.png";
import Logo from "../../../public/images/logo.svg";
import { useDispatch,useSelector } from "react-redux";
import { registerAsync,selectRegisterAuth } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";


const CreateProfile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [firstName, setFirstName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [middleShow, setMiddleShow] = useState(true)
  const [profileLoading,setProfileLoading]=useState(false)
  const register = useSelector(selectRegisterAuth)
  let data1 = JSON.parse(localStorage.getItem("phonedetails"))
  let emailData = localStorage.getItem("email")
  let locationData = JSON.parse(localStorage.getItem("location"))
  const registerPage = async() => {
    const dataM = {
      "firstname": firstName,
      "lastname": lastName,
      "middlename": middleName,
      "role_id": 3,
      "phone_code": data1?.phone_code,
      "email": emailData,
      "phone_no": data1?.phone_no,
      "state": locationData?.state,
      "city": locationData?.city
    }
    const data = {
      "firstname": firstName,
      "lastname": lastName,
      "email": emailData,
      "role_id": 3,
      "phone_code": data1?.phone_code,
      "phone_no": data1?.phone_no,
      "state": locationData?.state,
      "city": locationData?.city
    }
    let regName = /^[a-zA-Z]+$/;
    if (!firstName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your First name");
      }
      return false;
    }
    else if (firstName.length < 3) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Firstname should be of 3 digits");
      }
      return false;
    }
    else if(!regName.test(firstName)){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Invalid Firstname given.");
      }
      return false;
    }
    if (middleShow) {
      if (middleName.length < 3) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Middlename should be of 3 digits");
        }
        return false;
      }
      else if(!regName.test(middleName)){
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Invalid Middlename given.");
        }
        return false;
      }
    }
    
    if (!lastName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your Last name");
      }
      return false;
    }
    else if (lastName.length < 3) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Lastname should be of 3 digits");
      }
      return false;
    }
    else if(!regName.test(lastName)){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Invalid Lastname given.");
      }
      return false;
    }
    setProfileLoading(true)
    await dispatch(registerAsync(middleShow ? dataM : data))
    .then(unwrapResult)
      .then((obj) => {
    setProfileLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/register");
      }
      )
      .catch((obj) => {
    setProfileLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })

  };

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="logo-part">
          <Link to="#">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="delivery-driver cprof-prt">
          <img src={PrivacyProfile} alt="" />
        </div>

        <div className="driver-form">
          <form action="#" method="POST">
            <h2>
              Create<span>your Profile</span>
            </h2>
            <label htmlFor="n1">First name</label>
            <input type="text" id="n1" placeholder="Legal first name" value={firstName} onChange={e => setFirstName(e.target.value)} />
            <label htmlFor="n2">Middle name</label>
            <input type="text" id="n2" placeholder="Legal middle name" value={middleName} onChange={e => setMiddleName(e.target.value)} disabled={!middleShow} />
            <div className="form-group checkbox-item">
              <input type="checkbox" id="check" checked={!middleShow} onChange={() => setMiddleShow(prev => !prev)} />
              <label htmlFor="check">I don’t have middle name</label>
            </div>
            <label htmlFor="n3">Last name</label>
            <input type="text" id="n3" placeholder="Legal last name" value={lastName} onChange={e => setLastName(e.target.value)} />
            <button
              type="button" disabled={profileLoading===true }
              onClick={(e) => registerPage(e)} 
            >
            {profileLoading===true && (
              <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
              Next
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default CreateProfile;