import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChecklistApiAsync, selectChecklistData } from "./dashbaordSlice";
import { selectLoginAuth } from "../auth/authSlice";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

const Checklist = (props) => {
  const history = useHistory();
  const [checklist, setChecklist] = useState([])
  const [dashboardLoading, setDashboardLoading] = useState(false)
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const checklistData = useSelector(selectChecklistData)
  const [checkedState, setCheckedState] = useState(
    new Array(checklistData?.data?.payload?.data?.length).fill(false)
  );
  const handleChecklistChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  }
  const handleRequest = () => {
    if (checkedState.includes(false)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select all required check")
      }
    } else {
      history.push('/express-pickup-request')
    }
  }
  const checklistHandle = () => {
    setDashboardLoading(false)
    dispatch(getChecklistApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setDashboardLoading(false)
      }
      )
      .catch((obj) => {
        setDashboardLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  }
  useEffect(() => {
    checklistHandle()
  }, []);

  return (
    <>
      {dashboardLoading === true ?
        <div className="pageLoader d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div> :
        <div className="checklist-main">
          <div className="checklist-box">
            <h4>Check list</h4>
            <p>
              You need to check every things are perfectly fit for strat today’s
              drive.
            </p>
            <ul>
              {
                checklistData?.data?.payload?.data?.map((val, index) => {
                  return (
                    <li key={index}>
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          name={val?.id}
                          value={val?.id}
                          checked={checkedState[index]}
                          onChange={() => handleChecklistChange(index)}
                        />
                        <label htmlFor={`custom-checkbox-${index}`}>{val?.name}</label>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
            <div className="allset" onClick={handleRequest}>
              All set
            </div>
          </div>
        </div>}
    </>
  );
};

export default Checklist;