import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getDeliveryDetails,
    getVerifyPickupData,
    getReturnData
} from "./deliveryApi";

const initialState = {
    delivery: null,
    pickup: null,
    returnData: null,
    status: {
        delivery: 'idle',
        pickup: 'idle',
        returnData: 'idle'
    },
};

export const getDeliveryDetailsAsync = createAsyncThunk("delivery/waytodelivery", async (data) => {
    const response = await getDeliveryDetails();
    return response;
});
export const getPickupDetailsAsync = createAsyncThunk("delivery/pickup", async (data) => {
    const response = await getVerifyPickupData();
    return response;
});
export const getReturnDetailsAsync = createAsyncThunk("delivery/return", async (data) => {
    const response = await getReturnData();
    return response;
});

export const deliverySlice = createSlice({
    name: "delivery",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getDeliveryDetailsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDeliveryDetailsAsync.fulfilled, (state, { payload }) => {
                state.delivery = payload
                state.status = "idle";
            })
            .addCase(getDeliveryDetailsAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.delivery = null
            })
            .addCase(getPickupDetailsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getPickupDetailsAsync.fulfilled, (state, { payload }) => {
                state.pickup = payload
                state.status = "idle";
            })
            .addCase(getPickupDetailsAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.pickup = null
            })
            .addCase(getReturnDetailsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getReturnDetailsAsync.fulfilled, (state, { payload }) => {
                state.returnData = payload
                state.status = "idle";
            })
            .addCase(getReturnDetailsAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.returnData = null
            })
       
    },
});

export const selectDeliveryData = (state) => state.delivery.delivery;
export const selectPickupData = (state) => state.delivery.pickup;
export const selectReturnData = (state) => state.delivery.returnData;

export default deliverySlice.reducer;