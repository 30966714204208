import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import Doc1 from "../../../public/images/document-1.svg";
import Doc2 from "../../../public/images/document-2.svg";
import Doc3 from "../../../public/images/document-3.svg";
import Doc4 from "../../../public/images/document-4.svg";
import CustomModal from "../../../../app/components/shared/CustomModal"
import VerifyBlack from "../../../public/images/verify-black.svg";
import { useDispatch } from "react-redux";

const Register = (props) => {
  const history = useHistory();
  const [modalDetail, setModalDetail] = useState({
    show: false
  });
  const dispatch = useDispatch()
  const [modalKey, setModalKey] = useState(Math.random());

  const gotoRegistrationGuidelinePage = (e) => {
    e.preventDefault();
    history.push("/registration-guideline");
  };
  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setModalKey(Math.random());
  }

  useEffect(() => {
    setModalDetail({ show: true });
    setModalKey(Math.random());
    // dispatch(roleAsync(data))
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="registration-mainprt">
          <h2>Application progress</h2>
          <div className="document-box document-activebox">
            <div className="document-item">
              <img src={Doc1} alt="" />
              <div className="document-cnt">
                <h4>Guidelines</h4>
                <p>This is a custom stage.</p>
              </div>
            </div>
            <div className="document-btn">
              <Link to="#" onClick={(e) => { gotoRegistrationGuidelinePage(e) }}>Start</Link>
            </div>
          </div>
          <div className="document-box">
            <div className="document-item">
              <img src={Doc2} alt="" />
              <div className="document-cnt">
                <h4>Document Collection</h4>
                <p>This stage is about submitting a form like Quiz or Service agreement.</p>
              </div>
            </div>
            <div className="document-btn">
              <Link to="#">Start</Link>
            </div>
          </div>
          <div className="document-box">
            <div className="document-item">
              <img src={Doc3} alt="" />
              <div className="document-cnt">
                <h4>Agreement for Terms and Conditions</h4>
                <p>This stage is to confirm the agreement for the terms and conditions.</p>
              </div>
            </div>
            <div className="document-btn">
              <Link to="#">Start</Link>
            </div>
          </div>
          <div className="document-box">
            <div className="document-item">
              <img src={Doc4} alt="" />
              <div className="document-cnt">
                <h4>Document Review</h4>
                <p>This stage is about submitting a form like Quiz or Service agreement.</p>
              </div>
            </div>
            <div className="document-btn">
              <Link to="#">Start</Link>
            </div>
          </div>
        </div>
        <CustomModal
          key={modalKey}
          show={modalDetail.show}
          // backdrop="static"
          title={modalDetail.title}
          showCloseBtn={false}
          child={
            <div className="modal-content">
              <div className="modal-body">
                <div className="modal-mainprt modal-boxblack">
                  <div className="mdlverify-cnt">
                    <h4>Welcome</h4>
                    <img src={VerifyBlack} alt="" />
                    <p>You have created your profile.</p>
                    <h6>Successfully done!</h6>
                  </div>
                  <div className="mdlverify-btn mdllogin-btn">
                    <Link
                      to="#"
                      className="active"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOnCloseModal();
                      }}
                    >
                      Updated Information
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
          // size="lg"
          // footerContent = {}
          // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
          onCloseModal={() => handleOnCloseModal()}
        />
      </main>
    </>
  );
};

export default Register;