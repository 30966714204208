import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Profile from "../../public/images/profile.png";
import ParcelWhite from "../../public/images/parcel-white.svg";
import WalletWhite from "../../public/images/wallet-white.svg";
import UserWhite from "../../public/images/user-white.svg";
import RewardWhite from "../../public/images/reward-white.svg";
import TrackWhite from "../../public/images/track-white.svg";
import SettingsWhite from "../../public/images/settings-white.svg";
import SupportWhite from "../../public/images/support-white.svg";
import Power from "../../public/images/power.svg";
import { useSelector, useDispatch } from "react-redux";
import { selectUserProfile, selectProfileData, userProfileAsync } from "./sharedSlice"
import { logout, selectLoginProfile } from "../../containers/auth/authSlice";
import { updateProfileAsync } from "./sharedSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "./CommonLoader";
import { toast } from "react-toastify";

const Sidebar = (props) => {
  const history = useHistory();
  // const [profile, setProfile] = useState();
  const dispatch = useDispatch(userProfileAsync)
  const [loading, setLoading] = useState(false)
  const toastId = React.useRef(null)
  const selectProfile = useSelector(selectUserProfile)
  const [activeStatus, setActiveStatus] = useState(selectProfile?.payload?.user_profiles?.is_online)
  const selectLoginUser = useSelector(selectLoginProfile);
  let loginId = selectLoginUser?.payload?.id;

  const handleLogout = async () => {
    await dispatch(logout())
    window.localStorage.clear()
    await history.push('/')
  }
  const handleStatusChange = () => {
    let params = {
      loginId: selectLoginUser?.payload?.id,
      token: selectLoginUser.payload.token,
      is_online: !selectProfile?.payload?.user_profiles?.is_online
    }
    setLoading(true)
    dispatch(updateProfileAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        let params = {
          loginId: loginId,
          token: selectLoginUser.payload.token
        }
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        dispatch(userProfileAsync(params))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false)
            setActiveStatus(obj?.payload?.user_profiles?.is_online)
          }
          )
          .catch((obj) => {
            setLoading(false)
          })
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    let params = {
      loginId: loginId,
      token: selectLoginUser?.payload?.token
    }
    setLoading(true)
    dispatch(userProfileAsync(params))
    .then(unwrapResult)
    .then((obj) => {
      setLoading(false)
    }
    )
    .catch((obj) => {
      setLoading(false)
    })
  }, [])

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <>
      {
        loading ?
          <CommonLoader />
          :
          <div className="side-manubar">
            <div id="mySidenav" className="sidenav">
              <Link to="#" className="closebtn" onClick={closeNav}>
                &times;
              </Link>
              <div className="side-upper">
                <img src={selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : Profile} alt="Profile" />
                <div className="side-uprcnt">
                  <h4>{selectProfile?.payload?.user_profiles?.firstname} </h4>
                  <p>
                    Successful Delivery: <span>{selectProfile?.deliveries}</span>
                  </p>
                  <div className="status-onof">
                    <h6>Online</h6>
                    <label className="switch">
                      <input type="checkbox" value={activeStatus} checked={activeStatus} onChange={() => handleStatusChange()} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="sidemenu-links">
                <span className="line"></span>
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/my-deliveries");
                      }}
                    >
                      <img src={ParcelWhite} alt="" /> My Deliveries
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/wallet");
                      }}
                    >
                      <img src={WalletWhite} alt="" /> My Wallet
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/my-profile");
                      }}
                    >
                      <img src={UserWhite} alt="" /> My Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/reward");
                      }}
                    >
                      <img src={RewardWhite} alt="" /> Reward
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/app-tracker");
                      }}
                    >
                      <img src={TrackWhite} alt="" /> App tracker
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/settings");
                      }}
                    >
                      <img src={SettingsWhite} alt="" /> Settings
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="sidemenu-bottom mt-auto">
                <span className="line"></span>
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        closeNav();
                        history.push("/dashboard/support");
                      }}
                    >
                      <img src={SupportWhite} alt="" /> Support
                    </Link>
                  </li>
                  <li>
                    <Link to="#" onClick={() => handleLogout()}>
                      <img src={Power} alt="" /> <span>Log out</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      }

    </>
  );
};

export default Sidebar;