import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Timer from "../../public/images/timer-2.png";
import Network from "../../public/images/network.png";

const Online = (props) => {

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <div className="offline-main">
        <div className="overlay-off">
          <div className="timer-circle">
            <img src={Timer} alt="" />
          </div>

          <div className="offline-box">
            <img src={Network} alt="" />
            <h2>Your are Online Now!</h2>
            <p>
              Go online in order to start getting delivery request from
              customers and retailers
            </p>
            <Link
              className="active"
              to='/express-pickup-request'
            >
              Happy work
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Online;