import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getNotificationApiAsync, readNotificationApiAsync } from './dashbaordSlice'
import { selectLoginAuth } from '../auth/authSlice'
import CommonLoader from '../../components/shared/CommonLoader'
import { unwrapResult } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import PaginationSetting from '../../constants/PaginationSetting';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Notification = () => {
    const dispatch = useDispatch()
    const [pagination, setPagination] = useState(PaginationSetting);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false)
    const [notification, setNotification] = useState([])
    const auth = useSelector(selectLoginAuth)

    const handleReadNotification = (data) => {
        let params = {
            postData: {
                // "notification_id": data
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(readNotificationApiAsync(params))
            .then(unwrapResult)
            .then(async (obj) => {
                let getNotiParams = {
                    postData: {
                        page: page,
                        limit: pagination.recordsPerPage
                    },
                    tokenData: auth?.payload?.token
                }
                dispatch(getNotificationApiAsync(getNotiParams))
                    .then(unwrapResult)
                    .then(async (obj) => {
                        setNotification(obj?.data?.payload?.data)
                        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                        setLoading(false)
                    }
                    )
                    .catch((obj) => {
                        setLoading(false)
                    })
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    useEffect(() => {
        let getNotiParams = {
            postData: {
                page: page,
                limit: pagination.recordsPerPage
            },
            tokenData: auth?.payload?.token
        }
        setLoading(true)
        dispatch(getNotificationApiAsync(getNotiParams))
            .then(unwrapResult)
            .then(async (obj) => {
                setNotification(obj?.data?.payload?.data)
                setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
        handleReadNotification()
    }, [page])
    console.log(notification, "notification response")

    return (
        <>
            <div className="registration-upper reg-heading2">
                <h4>
                    <Link
                        to="/express-pickup-request"
                    >
                        {" "}
                        <i className="fas fa-chevron-left"></i>{" "}
                    </Link>{" "}
                    Notifications
                </h4>
            </div>
            {
                loading ?
                    <CommonLoader />
                    :
                    <div className="main-content">
                        <div className="notification-part">
                            <div className="notification-sec">
                                {/* <h2>Today</h2> */}
                                {notification?.length > 0 ?
                                    notification.map((data, index) => {
                                        return (
                                            <div key={index} className={data?.is_read === false ? "notification-item active-notifybox" : "notification-item"}>
                                                {/* <img src={ncigar} alt="" /> */}
                                                <div className="notification-cnt" onClick={() => handleReadNotification(data?.notification?.id)} style={{ cursor: "pointer" }}>
                                                    <h4>{data?.notification?.title} <span>{moment(data?.created_at).format('hh : mm')}m ago</span></h4>
                                                    <p>{data?.notification?.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <>No notifications found</>}
                            </div>
                        </div>
                    </div>
            }
            {pagination.totalRecords > pagination.recordsPerPage &&
                <Box py={3} display="flex" justifyContent="center" className="pagination_content">
                    <Pagination
                        key={page}
                        count={pagination.totalPages || 0}
                        color="secondary"
                        variant="outlined"
                        onChange={(e, value) => setPage(value)}
                        defaultPage={page}
                    />
                </Box>
            }
        </>
    )
}

export default Notification