import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Order from "../../../../public/images/order-prof.png";
import Tell from "../../../../public/images/tell.svg";
import Chat from "../../../../public/images/chat.svg";
import Cross from "../../../../public/images/cross.svg";
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryDetailsAsync } from "../../deliverySlice"
import CustomModal from "../../../../components/shared/CustomModal"
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import downArrow from "../../../../public/images/downArrow.svg"
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
} from '@react-google-maps/api'
const WaitingOnDoor = (props) => {
  const history = useHistory();
  const [libraries] = useState(['places']);
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const [loading] = useState(false)
  const dispatch = useDispatch()
  const [middlebody, setmiddlebody] = useState(false);
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries,
  })
  const center = { lat: 30.6574932, lng: 76.727102 }

  async function calculateRoute() {
    if (locationData?.order_pick_address === '' || locationData?.order_delivery_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: locationData?.order_delivery_address,
      destination: locationData?.order_pick_address,
      // eslint-disable-next-line no-undef 
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
  }
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">0</div>;
    }
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  }
  const handleComplete = () => {
    setModalDetail({ show: true }); setKey(Math.random());
  }

  useEffect(() => {
    dispatch(getDeliveryDetailsAsync())
    calculateRoute()
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            {
              isLoaded ?
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={map => setMap(map)}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap> :
                <></>}
          </div>
          {" "}
        </div>

        {!modalDetail.show ? <div className="reqaccpt-exprs text-center">
          <span>
            Expree Delivery request accepted: <strong>1</strong>
          </span>
        </div> : ""}


        <div className="track-lcitem track-lctdl3">

        </div>

        {!modalDetail.show ?
          <div className="pickup-pointbox">
            <div className="accept-mdlupper" onClick={() => handleClick(true)}>
              <h2>
                Waiting<span>On Door Step</span>
              </h2>
              <img className="dropArrow" src={downArrow} />
              <div className="timer-wrapper">
                <div className="timer">
                  <CountdownCircleTimer
                    isPlaying
                    duration={30}
                    // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                    // colorsTime={[10, 6, 3, 0]}
                    colors={"#1FB3FF"}
                    onComplete={handleComplete}
                    size={58}
                    strokeWidth={8}
                  >
                    {renderTime}
                  </CountdownCircleTimer>
                </div>
              </div>
            </div>
            <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
              <div className="accept-mdltimline">
                <div className="order-prt">
                  <img src={locationData?.user_info?.user_profiles?.profile_photo ? locationData?.user_info?.user_profiles?.profile_photo :Order} alt="" />
                  <div className="order-cnt">
                    <h2>{`${locationData?.user_info?.user_profiles?.firstname} ${locationData?.user_info?.user_profiles?.lastname}`}</h2>
                    <p>{locationData?.order_delivery_address}</p>
                    <h2>Order#</h2>
                    <p>{locationData?.order_id}</p>
                    <ul>
                      <li>
                        <Link to="#">
                          <img src={Tell} alt="" /> Call
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/dashboard/delivery/message", {
                              fromWaitingOnDoor: true,
                            });
                          }}
                        >
                          <img src={Chat} alt="" /> Message
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="pickup-mbtn">
                <button className="btn btn-primary continue" disabled={loading === true} onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }}>
                {loading === true && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                Cancel delivery</button>
              </div>
            </div>
          </div>
          : ""}
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={true}
        isRightSideModal={true}
        child={
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal-mainprt modal-boxblack">
                <div className="mdlverify-cnt">
                  <h4>Canceled</h4>
                  <img src={Cross} alt="" />
                  <p>You have to return to shop</p>
                  <h6>Return the order</h6>
                </div>
                <div className="mdlverify-btn mdllogin-btn init-mdlbtn">
                  <Link to="#" onClick={() => history.push({ pathname: '/dashboard/express-delivery/return/way-to-return', state: JSON.stringify(locationData) })} className="active">
                    Initiate return
                  </Link>
                </div>
              </div>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default WaitingOnDoor;

