import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import LeftArrow from "../../public/images/left-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { getTripDataApiAsync } from "./dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../components/shared/CommonLoader";
import { toast } from "react-toastify";

const AppTrackerList = (props) => {
  const history = useHistory();
  const [filterData, setFilterData] = useState("day")
  const [loading, setLoading] = useState(false)
  const [trips, setTrips] = useState([])
  const auth = useSelector(selectLoginAuth)
  const toastId = React.useRef(null)
  const dispatch = useDispatch()
  const handleChange = async (e) => {
    setFilterData(e.target.value)
  }

  const getListOfDelivery = () => {
    setLoading(true)
    let data = {
      "postData": auth?.payload?.uuid,
      "tokenData": auth?.payload?.token
    }
    dispatch(getTripDataApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setTrips(obj?.data?.payload?.data)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  console.log(trips, "response of delivery")
  useEffect(() => {
    getListOfDelivery()
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
              history.push("/dashboard/app-tracker");
            }}
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          Total trips
        </h4>
        <div className="fuel-btn">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/dashboard/app-tracker-list-2");
            }}
          >
            Add fuel
          </Link>
        </div>
      </div>
      {
        loading ?
          <CommonLoader /> :
          <div className="tracklist-main">
            <div className="tracklist-search">
              <form action="#" method="POST">
                <input type="search" placeholder="Search here" />
              </form>
            </div>

            <div className="trip-upper">
              <h4>Trips</h4>
              <form action="#" method="POST">
                <select name="" id="" onChange={(e) => handleChange(e)}>
                  <option value="day">Daily</option>
                  <option value="week">Weekly</option>
                  <option value="month">Monthly</option>
                  <option value="year">Yearly</option>
                </select>
              </form>
            </div>

            <div className="apptrack-table">
              <table>
                <thead>
                  <tr>
                    <th>Trip</th>
                    <th>Distance</th>
                    <th>Fuel</th>
                    <th>Cost</th>
                    <th>Earned</th>
                  </tr>
                </thead>
                <tbody>
                  {trips.length > 0 ? (
                    <>
                      {trips.map((value, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{moment(value?.accepted_at).format('MMM DD, YYYY')}</td>
                              <td>{value?.distance} km</td>
                              <td>{value?.fuel_usage} gal</td>
                              <td>${value?.fuel_cost}</td>
                              <td>${value?.earning}</td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>No Trips Found</>
                  )}
                </tbody>
              </table>
            </div>
          </div>
      }

    </>
  );
};

export default AppTrackerList;