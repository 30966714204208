import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import Req1 from "../../../app/public/images/req-1.png";
import Tell from "../../public/images/tell.svg";
import Email from "../../public/images/email.svg";
import Location from "../../public/images/location.svg";
import SupportProf from "../../public/images/support-prof.png";
import Inf1 from "../../public/images/inf-1.png";
import { useSelector, useDispatch } from "react-redux";
import { getSupportApiAsync, getSupportDetailsAsync, selectSupportData, selectSupportDetailsData } from "../../containers/dashboard/dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import moment from 'moment-timezone'

const SupportDetails = (props) => {
  const history = useHistory();
  const location =useLocation();
  const dispatch = useDispatch()
  const auth = useSelector(selectLoginAuth)
  const supportDetails = useSelector(selectSupportDetailsData);
  const supportData = useSelector(selectSupportData);
  const result = supportData?.data?.payload?.data?.filter(word => word.id === location?.state);
  console.log(result,"result")
  useEffect(() => {
    const data = {
      "tokenData": auth?.payload?.token
    }
    dispatch(getSupportDetailsAsync())
    dispatch(getSupportApiAsync(data))
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2 support-header">
        <h4>
          <Link
            to="/dashboard/support"
          >
            {" "}
            <i className="fas fa-chevron-left"></i>{" "}
          </Link>{" "}
          #{result[0]?.track_number}
        </h4>
        <div className="info-reqall">
          <Link to="#">
            <i className="fas fa-info-circle"></i>
          </Link>
          <div className="reqdetails-info">
            <div className="reqdet-infobox">
              <div className="reqdetails-upper">
                <img src={auth?.payload?.user_profiles?.profile_photo ? auth?.payload?.user_profiles?.profile_photo : Req1} alt="" />
                <div className="reqdetails-uprcnt">
                  <h4>{auth?.payload?.user_profiles?.firstname}</h4>
                  <p>Me</p>
                </div>
              </div>

              <ul>
                <li>
                  <img src={Tell} alt="" /> {auth?.payload?.user_profiles?.phone_no}
                </li>
                <li>
                  <Link to="#">
                    <img src={Email} alt="" /> {auth?.payload?.user_profiles?.email ? auth?.payload?.user_profiles?.email : "xyz@gmail.com"}
                  </Link>
                </li>
                <li>
                  <img src={Location} alt="" />
                  {supportDetails?.userData.address}
                </li>
              </ul>

              <div className="reqdet-infobtm">
                <h4>
                  Total Tickets:{" "}
                  <strong>{supportDetails?.userData.ticketsData.total}</strong>
                </h4>
                <ul>
                  <li>
                    Solved:{" "}
                    <strong>
                      {supportDetails?.userData.ticketsData.solved}
                    </strong>
                  </li>
                  <li>
                    Cancelled:{" "}
                    <strong>
                      {supportDetails?.userData.ticketsData.cancelled}
                    </strong>
                  </li>
                  <li>
                    Open:{" "}
                    <strong>{supportDetails?.userData.ticketsData.open}</strong>
                  </li>
                  <li>
                    Unrespond:{" "}
                    <strong>
                      {supportDetails?.userData.ticketsData.unattended}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
            <div className="reqdet-infobox2">
              <h4>Assigned agent:</h4>
              <div className="reqinfo-item">
                <img src={SupportProf} alt="" />
                <div className="reqinfo-cnt2">
                  <h6>{supportDetails?.agentData.name}</h6>
                  <p>
                    {supportDetails?.agentData.assignedDate} |{" "}
                    {supportDetails?.agentData.assignedTime}
                  </p>
                </div>
              </div>
              <h4>Also on this ticket:</h4>
              <ul>
                {supportDetails?.agentData.otherAgentsData.length ? (
                  <>
                    {supportDetails?.agentData.otherAgentsData.map(
                      (value, index) => {
                        return (
                          <React.Fragment key={index}>
                            <li>
                              <img src={Inf1} alt="" />
                              {value.name}
                            </li>
                          </React.Fragment>
                        );
                      }
                    )}
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* listing */}
      <div className="reqdetails-area">
        <div className="reqdetails-part">
          <div className="reqdetails-upper">
          <img src={auth?.payload?.user_profiles?.profile_photo ? auth?.payload?.user_profiles?.profile_photo : Req1} alt="" />
            <div className="reqdetails-uprcnt">
              <h4>{auth?.payload?.user_profiles?.firstname}</h4>
              <p>Me</p>
            </div>
          </div>
          <div className="reqdetails-content">
            <p>{result[0]?.notes}</p>
            <h6>
              {moment(result[0]?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(result[0]?.created_at).format('hh:mm A')}
            </h6>
          </div>
        </div>
        {
          result[0]?.support_comments?.map((val, index) => {
            return (
              <div className="reqdetails-part border-0" key={index}>
                <div className="reqdetails-upper">
                  <img src={val?.user_id_details?.profile_photo} alt="" />
                  <div className="reqdetails-uprcnt">
                    <h4>{val?.user_id_details?.firstname}{" "}{val?.user_id_details?.lastname}</h4>
                    <p>JOBR Agent</p>
                  </div>
                </div>
                <div className="reqdetails-content">
                  <p>{val?.comment}</p>
                  <p>--------------</p>
                  <p>
                    Regards, <br />
                    {val?.user_id_details?.firstname}{" "}{val?.user_id_details?.lastname} <br />
                    Jobr.com Support Team
                  </p>
                  <h6>
                    {moment(val?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(val?.created_at).format('hh:mm A')}
                  </h6>
                </div>
              </div>
            )
          })
        }
      </div>
    </>
  );
};


export default SupportDetails;
