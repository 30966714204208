import React from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import LeftArrow from "../../../public/images/left-arrow.svg";
import { usePlaidLink } from 'react-plaid-link';
import { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { generatePlaidTokenApiAsync, getBankAccountApiAsync, linkBankAccountAsync, selectLoginAuth } from '../authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';

const DocumentSuccess = () => {
    const history = useHistory()
    const [linkToken, setLinkToken] = useState("")
    const [bankDetails, setBankDetails] = useState([])
    const auth = useSelector(selectLoginAuth)
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            // send public_token to server
            console.log(public_token);
            let params = {
                postData: {
                    "plaid_public_token": public_token
                },
                tokenData: auth?.payload?.token
            }
            dispatch(linkBankAccountAsync(params))
                .then(unwrapResult)
                .then((obj) => {
                    dispatch(getBankAccountApiAsync(auth?.payload?.token))
                        .then(unwrapResult)
                        .then((obj) => {
                            console.log(obj, "bank data")
                            setBankDetails(obj?.payload)
                        }
                        )
                        .catch((obj) => {
                        })
                }
                )
                .catch((obj) => {
                })
        },
    });
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(generatePlaidTokenApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLinkToken(obj?.payload?.link_token)
            }
            )
            .catch((obj) => {
            })
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                console.log(obj, "bank data")
                setBankDetails(obj?.payload)
            }
            )
            .catch((obj) => {
            })
    }, [])

    return (
        <>
            <main className="main-wrapper kycwrapper">
                <div className="registration-upper reg-heading2">
                    <h4><Link to="#">
                        <img src={LeftArrow} alt="" />
                    </Link>{" "}
                        Add Bank account
                    </h4>
                </div>

                {bankDetails?.length > 0 ? <>
                    {
                        bankDetails?.map((val, index) => {
                            return (
                                <Card body key={index}>
                                    <div className='d-flex'>
                                        <h6>A/C No:- </h6>{"  "}
                                        <span>{val?.account_number}</span>
                                    </div>
                                    <div className='d-flex'>
                                        <h6>A/C Holder Name:- </h6>{"  "}
                                        <span>{val?.account_owner_name}</span>
                                    </div>
                                </Card>
                            )
                        })
                    }
                </> : <div className='text-center pt-5'>
                    <h6>Document are uploaded successfully.</h6>
                </div>}



                <div className='bottomBtnbox'>
                    <button type="submit" className="document_button blueprimeBtn" onClick={() => open()} disabled={!ready}>
                        {!ready && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        Add Bank account</button>

                       {bankDetails?.length > 0 && <button type="submit" className="document_button blueprimeBtn" onClick={()=> history.push('/registration-doc-dd')}>
                        Continue</button>}
                </div>
            </main>
        </>
    )
}

export default DocumentSuccess