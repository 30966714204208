import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../../public/images/left-arrow.svg";
import { businessRegistrationApiAsync, createWalletAsync, driverDocumentStepAsync, getBusinessTypesAsync, getNaicsCategoriesAsync, selectBusinessType, selectLoginAuth, selectLoginProfile, selectNaicsCategory, selectUserProfile, selectWalletProfile, userProfileAsync } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const BusinessRegistration = (props) => {
    const history = useHistory();
    const [dob, setDob] = useState("")
    const [employerIdentification, setEmployerIdentification] = useState("")
    const [apt, setApt] = useState("")
    const [zipcode, setZipCode] = useState("")
    const [countryCode, setCountryCode] = useState("")
    const [address, setAddress] = useState("")
    const [stateCode, setStateCode] = useState("")
    const [uuid, setUuid] = useState("")
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const [loading, setLoading] = useState(false)
    const [countryValue, setCountryValue] = useState("");
    const [stateValue, setStateValue] = useState("")
    const [cityValue, setCityValue] = useState("")
    const [email, setEmail] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [businessWebsite, setBusinessWebsite] = useState("")
    const [businessType, setBusinessType] = useState("")
    const [designation, setDesignation] = useState("")
    const [naicsCode, setNaicsCode] = useState("")
    const businessTypeList = useSelector(selectBusinessType)
    const naicsCategoryList = useSelector(selectNaicsCategory)
    const selectProfile = useSelector(selectUserProfile);

    const autoCompleteHandleChange = (address) => {
        if (address === "") {
            setCityValue("")
            setStateValue("")
            setCountryValue("")
            setZipCode("")
            setApt("")
        }
        setAddress(address);
    };

    const autoCompleteHandleSelect = (address) => {
        geocodeByAddress(address)
            .then((results) => {
                fillAddressDetails(results);
            })
            .catch((error) => { });
    };

    const fillAddressDetails = (results) => {
        setAddress(results[0].formatted_address);

        for (let j = 0; j < results[0].address_components.length; j++) {
            if (results[0].address_components[j].types[0] == "postal_code") {
                setZipCode(results[0].address_components[j].short_name);
            } else if (results[0].address_components[j].types[0] == "locality") {
                setCityValue(results[0].address_components[j].long_name);
            } else if (
                results[0].address_components[j].types[0] ==
                "administrative_area_level_1" ||
                results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
                results[0].address_components[j].types[0] === 'locality'
            ) {
                setStateValue(results[0].address_components[j].long_name);
                setStateCode(results[0].address_components[j]?.short_name)
            } else if (results[0].address_components[j].types[0] == "country") {
                setCountryValue(results[0].address_components[j].long_name);
                setCountryCode(results[0].address_components[j]?.short_name);
            }
            else if (results[0].address_components[j].types[0] == 'street_number') {
                setApt(results[0].address_components[j].long_name);
            }
        }
    };
    const auth = useSelector(selectLoginAuth)
    console.log(auth, "auth data")

    const handleBusinessRegistration = async (e) => {
        e.preventDefault();
        if (!dob) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your DOB");
            }
            return;
        }
        if (!employerIdentification) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter employer identification number");
            }
            return;
        }
        if (!email) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your email");
            }
            return;
        }
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        let resultemail = pattern.test(email);

        let urlPattern = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
        let resultUrl = urlPattern.test(businessWebsite);
        if (resultemail === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid email");
            }
            return;
        }
        if (!businessName) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your business name");
            }
            return;
        }
        if (!businessWebsite) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your business webiste");
            }
            return;
        }
        if (resultUrl === false) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter valid webiste url");
            }
            return;
        }
        if (!businessType) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select your business type");
            }
            return;
        }
        if (!naicsCode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please select your naics code");
            }
            return;
        }
        if (!address.length > 0) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your street address");
            }
            return;
        }
        if (!cityValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter City")
            }
            return
        }
        if (!stateValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter state")
            }
            return
        }
        if (!zipcode) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your zipcode");
            }
            return;
        }
        if (!countryValue) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter country")
            }
            return
        }
        setLoading(true)

        let businessData = {
            postData: {
                "address_type": "current",
                "address": address,
                "city": cityValue,
                "state": stateCode,
                "zip": zipcode,
                "country": countryCode,
                "phone": selectProfile?.payload?.user_profiles?.phone_no,
                "email": email,
                "dob": moment(dob).format('YYYY-MM-DD'),
                "entity_name": businessName,
                "business_type": businessType,
                "business_type_uuid": uuid,
                "business_website": businessWebsite,
                "naics_code": naicsCode,
                "business_registration_state": stateCode,
                "doing_business_as": designation,
                "employer_identification_number": employerIdentification
            },
            tokenData: auth?.payload?.token
        }
        await dispatch(businessRegistrationApiAsync(businessData))
            .then(unwrapResult)
            .then(async (obj) => {
                history.push('/kyb-verification')
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    };

    const handleBusinessType = (e) => {
        let uuid = businessTypeList?.payload?.filter((val) => val.name === e.target.value)[0]?.uuid
        setUuid(uuid)
        setBusinessType(e.target.value)
    }
    const handleNaicsCode = (e) => {
        setNaicsCode(e.target.value)
    }

    useEffect(() => {
        dispatch(getBusinessTypesAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
            }
            )
            .catch((obj) => {
            })
        dispatch(getNaicsCategoriesAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
            }
            )
            .catch((obj) => {
            })
        let params = {
            loginId: auth?.payload?.id,
            token: auth?.payload?.token
        }
        dispatch(userProfileAsync(params))
    }, [])
    return (
        <>
            <main className="main-wrapper">
                <div className="registration-upper reg-heading2">
                    <h4>
                        <Link to="/id-verification">
                            <img src={LeftArrow} alt="" />
                        </Link>{" "}
                        Business Registration
                    </h4>
                </div>

                <div className="personal-infomain">
                    <h2>Personal Information</h2>
                    <label htmlFor="p1">Date of birthday (month / day / year)</label>
                    <form>
                        <DatePicker
                            selected={dob}
                            onChange={(date) => setDob(date)}
                            dateFormat="MM/dd/yyyy"
                            maxDate={moment().subtract(21, 'years')._d}
                            placeholderText="MM/DD/YYYY"
                            className="date"
                            autoComplete="off"
                        />
                    </form>
                    <input type="text" placeholder="Employer Identification Number" value={employerIdentification} onChange={e => setEmployerIdentification(e.target.value)} />
                    <input type="email" placeholder="Please enter your email" value={email} onChange={e => setEmail(e.target.value)} />

                    <input type="text" placeholder="Business Name" value={businessName} onChange={e => setBusinessName(e.target.value)} />
                    <input type="text" placeholder="Business Website" value={businessWebsite} onChange={e => setBusinessWebsite(e.target.value)} />
                    <input type="text" placeholder="Doing Business as" value={designation} onChange={e => setDesignation(e.target.value)} />

                    <select className="country-name" defaultValue={"Select Business Type"} name="businessType" id="businessType" onChange={(e) => handleBusinessType(e)}>
                        <option value="Select Business Type" disabled>Select Business Type</option>
                        {(businessTypeList && businessTypeList?.payload.length > 0) && businessTypeList?.payload.map((value, index) => {
                            return (
                                <option value={value?.name} uuid={value?.uuid} key={index}>{value?.label}</option>
                            )
                        })}
                    </select>

                    <select className="country-name" defaultValue={"Select Naics Code"} name="naicsCode" id="naicsCode" onChange={(e) => handleNaicsCode(e)}>
                        <option value="Select Naics Code" disabled>Select Naics Code</option>
                        {naicsCategoryList?.payload.map((value, index) => {
                            return (
                                <option value={value?.code} key={index}>{value?.subcategory}</option>
                            )
                        })}
                    </select>

                    <label htmlFor="p2">Current Addreess</label>
                    <PlacesAutocomplete
                        value={address}
                        onChange={autoCompleteHandleChange}
                        onSelect={autoCompleteHandleSelect}
                        searchOptions={{
                            componentRestrictions: {
                                country: ['us'],
                            }
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Street Address',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                key={index}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>

                    <input type="text" placeholder="Apt. " value={apt} onChange={e => setApt(e.target.value)} />
                    <input type="text" placeholder="Please enter city " value={cityValue} onChange={e => setCityValue(e.target.value)} />
                    <input type="text" placeholder="Please enter state " value={stateValue} onChange={e => setStateValue(e.target.value)} />
                    <input type="text" placeholder="Zip Code" value={zipcode} onChange={e => setZipCode(e.target.value)} />
                    <input type="text" placeholder="Please enter country " value={countryValue} onChange={e => setCountryValue(e.target.value)} />

                    <button type="submit" className="document_button" disabled={loading === true} onClick={(e) => handleBusinessRegistration(e)}>
                        {loading === true && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        Next Step
                    </button>
                </div>
            </main>
        </>
    );
};

export default BusinessRegistration;