import { unwrapResult } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../public/images/logo.svg";
import SentMessage from "../../../public/images/sent-message.png";
import { emailSendAsync } from "../authSlice";

const RegisterEmail = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [emailLoading, setEmailLoading] = useState(false)
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    localStorage.setItem("email", email)
    const data = {
      "type": "email",
      "email": email
    }
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your email");
      }
      return;
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var resultemail = pattern.test(email);

    if (resultemail === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid email");
      }
      return;
    }
    setEmailLoading(true)
    await dispatch(emailSendAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setEmailLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push({ pathname: '/verify-email', state: email })
      }
      )
      .catch((obj) => {
        setEmailLoading(false)
      })
  }

  return (
    <>
      <div className="logo-part">
        <Link to="#">
          <img src={Logo} alt="" />
        </Link>
      </div>

      <div className="lock-part sent-message">
        <img src={SentMessage} alt="" />
      </div>

      <div className="regmain-part">
        <h2>
          Verify your <strong>email address</strong>
        </h2>
        <p>Please enter your email adress</p>
        <div className="email-box">
          <form action="#" name="POST">
            <input type="email" placeholder="yourname@email.com" value={email} onChange={(e) => { setEmail(e.target.value) }} />
          </form>
        </div>
      </div>
      <div className="bottom-part">
        <button className="btn btn-primary continue" disabled={emailLoading === true} onClick={handleSubmit}>
          {emailLoading === true && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
          Send Code
        </button>
      </div>
    </>
  );
};

export default RegisterEmail;