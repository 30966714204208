import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LeftArrow from "../../public/images/left-arrow.svg";
import LogoIcon from "../../public/images/logo_icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { getRewardAsync, getRideHistoryApiAsync, selectMyRewardsData, selectRideHistoryData } from "../../containers/dashboard/dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";
import { Line } from 'rc-progress';
import CommonLoader from "../../components/shared/CommonLoader";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";

const Reward = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const reward = useSelector(selectMyRewardsData);
  const auth = useSelector(selectLoginAuth)
  const rideData = useSelector(selectRideHistoryData)
  console.log(reward, "reward data")
  const getRewardAsyncFunc = () => {
    setLoading(true)
    dispatch(getRewardAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const getRideHistoryFun = () => {
    setLoading(true)
    dispatch(getRideHistoryApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getRewardAsyncFunc()
    getRideHistoryFun()
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          Rewards
        </h4>
      </div>
      {
        loading ?
          <CommonLoader />
          :
          <div className="reward-main">
            {/* {reward?.rewardData.category === "bronze" && (
              <> */}
            <div className={(reward?.data?.payload?.member_ship === "bronze") ?
              "reward-box" : (reward?.data?.payload?.member_ship === "silver") ? "reward-box reward-silver" : (reward?.data?.payload?.member_ship === "gold") ? "reward-box reward-gold" : (reward?.data?.payload?.member_ship === "platinum") ? "reward-box reward-silver reward-platinum" : ""}>
              <h4>
                {auth?.payload?.user_profiles?.firstname}{" "}{auth?.payload?.user_profiles?.lastname}
                <span>
                  <i className="fas fa-trophy"></i>{" "}
                  {reward?.data?.payload?.availabe_points}
                </span>
              </h4>
              <h3>
                <strong>{reward?.data?.payload?.member_ship}</strong>{" "}
                <span>{reward?.data?.payload?.reward_range}</span>
              </h3>
              <Line className="progress_bar" trailColor="#FFFFFF" percent={((reward?.data?.payload?.availabe_points / reward?.data?.payload?.reward_range) * 100)} strokeWidth={3} trailWidth={3} strokeColor={reward?.data?.payload?.member_ship === "bronze" ?
                "#C29958" :
                reward?.data?.payload?.member_ship === "silver" ?
                  "#C3C7C7" :
                  reward?.data?.payload?.member_ship === "gold" ?
                    "#B5930E" :
                    "#DFE5E7"} />
              <p>Points will be used for redeem gifts*</p>
            </div>
            {/* </>
            )} */}

            {/* <div className="reward-collectbox">
    <h2>Rewards</h2>
    <div className="collect-item">
      <div className="collect-inner">
        <img src={LogoIcon} alt="" />
        <div className="collect-cnt">
          <h4>JBR {reward?.rewardData.amount}</h4>
          <p>{reward?.rewardData.totalPoints} points</p>
        </div>
      </div>
      <Link to="#">Collect</Link>
    </div>
  </div> */}

            <div className="point-history">
              <h4>Point history</h4>
              <ul>
                {rideData?.data?.payload?.data?.length > 0 ? (
                  <>
                    {rideData?.data?.payload?.data.map((value, index) => {
                      return (
                        <React.Fragment key={index}>
                          <li>
                            <div className="history-left">
                              <h4>Delivery Completed</h4>
                              <span>{moment(value?.created_at).format('MMM DD, YYYY')}</span>
                            </div>
                            <div className="history-right">
                              <span>{value.points} Points</span>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <>No History Found</>
                )}
              </ul>
            </div>
          </div>
      }

    </>
  );
};

export default Reward;