import React from 'react'

const CommonLoader = () => {
    return (
        <>
            <div className="centeredLoader">
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
                <div className="loader">
                    <div className="dot"></div>
                </div>
            </div>
        </>
    )
}

export default CommonLoader