import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../../public/images/left-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { driverDocumentStepAsync, selectLoginAuth, vehicleMakeAsync, vehicleModelYearAsync } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../../components/shared/CommonLoader";

const RegistrationDocVd = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [vehicleMake, setVehicleMake] = useState([])
  const [vehicleModel, setVehicleModel] = useState([])
  const [vehicleYear, setVehicleYear] = useState([])
  const [loading, setLoading] = useState(false)
  const [vdLoading, setVdLoading] = useState(false)
  const [makeValue, setMakeValue] = useState("")
  const [modelValue, setModelValue] = useState("")
  const [vinValue, setVinValue] = useState("")
  const [yearValue, setYearValue] = useState("")
  const [mileageValue, setMileageValue] = useState("")
  const auth = useSelector(selectLoginAuth)

  let data = {
    "postData": {
      "driver_steps": "3",
      "vehical_make": makeValue,
      "vehical_year": yearValue,
      "vehical_model": modelValue,
      "vehical_mileage": mileageValue,
      "vehical_vin": vinValue
    },
    "tokenData": auth?.payload?.token
  }

  const gotoRegistrationDocVrdPage = async (e) => {
    e.preventDefault();
    if (!makeValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select vehicle make");
      }
      return;
    }
    if (!modelValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select vehicle model");
      }
      return;
    }
    if (!yearValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select vehicle year");
      }
      return;
    }
    if (!vinValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter vehicle vin");
      }
      return;
    }
    if (!mileageValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter vehicle mileage");
      }
      return;
    }
    setVdLoading(true)
    await dispatch(driverDocumentStepAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setVdLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/registration-doc-vrd");
      }
      )
      .catch((obj) => {
        setVdLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  };

  const getVehicleMake = () => {
    setLoading(true)
    dispatch(vehicleMakeAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setVehicleMake(obj?.payload?.make)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const handleMake = (data) => {
    setMakeValue(data)
    let params = {
      postData: { "make": data },
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(vehicleModelYearAsync(params))
      .then(unwrapResult)
      .then((obj) => {
        setVehicleModel(obj?.payload?.model)
        setVehicleYear(obj?.payload?.year)
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getVehicleMake()
  }, []);

  return (
    <>
      <main className="main-wrapper vichleDetail">
        <div className="registration-upper reg-heading2">
          <h4><Link to="/registration_dco-dd"><img src={LeftArrow} alt="" /></Link> Documents collections</h4>
        </div>

        <div className="personal-infomain vehicle-dtmain">
          <h2>Vehicle details</h2>
          <p>Enter your vehicle information</p>
          <div className="vehicle-inneritem">
            <select name="vehicle_make" defaultValue={"Make"} id="districtlist1" onChange={(e) => handleMake(e.target.value)}>
              <option value="Make" disabled>Make</option>
              {vehicleMake?.map((value, index) => {
                return (
                  <option value={value} key={index}>{value}</option>
                )
              })}
            </select>
            <select name="vehicle_model" defaultValue={"Model"} id="districtlist2" disabled={!makeValue} onChange={(e) => setModelValue(e.target.value)}>
              <option value="Model" disabled>Model</option>
              {vehicleModel?.map((value, index) => {
                return (
                  <option value={value} key={index}>{value}</option>
                )
              })}
            </select>

            <select name="vehicle_year" defaultValue={"Year"} id="districtlist3" disabled={!makeValue} onChange={(e) => setYearValue(e.target.value)}>
              <option value="Year" disabled>Year</option>
              {vehicleYear?.map((value, index) => {
                return (
                  <option value={value} key={index}>{value}</option>
                )
              })}
            </select>

            <input type="text" name="vin" placeholder="Vin" value={vinValue} onChange={e => setVinValue(e.target.value)} />
            <input type="text" name="mileage" placeholder="Mileage" value={mileageValue} onChange={e => setMileageValue(e.target.value)} />
          </div>

          <button type="button" className="document_button verifyDocbtn" disabled={vdLoading === true} onClick={(e) => gotoRegistrationDocVrdPage(e)}>
            {vdLoading === true && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            Next (Step 3/5)</button>
        </div>
      </main>
    </>
  );
};

export default RegistrationDocVd;