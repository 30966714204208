import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Maison-Mono_Medium.ttf'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import "react-phone-number-input/style.css";
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';

import NetworkService from "./utilities/interceptor"

NetworkService.setupInterceptors(store);

// let persistor = persistStor(store);
ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
</React.StrictMode>,
document.getElementById("root")
);
reportWebVitals();