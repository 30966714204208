import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LeftArrow from "../../public/images/left-arrow.svg";
import Edit from "../../public/images/edit.svg";
import Profile from "../../public/images/profile-img.png";
import UserBlue from "../../public/images/user-blue.svg";
import EmailBlue from "../../public/images/email-blue.svg";
import Tell from "../../public/images/tell.svg";
import VerifyGreen from "../../public/images/verify-green.svg";
import { useSelector, useDispatch } from "react-redux";
import { selectLoginAuth ,selectLoginProfile} from "../auth/authSlice";
import { selectUserProfile, userProfileAsync } from "../../components/shared/sharedSlice";

const MyProfile = (props) => {
  const dispatch = useDispatch();
  const profileData = useSelector(selectLoginAuth)
  const selectProfile = useSelector(selectUserProfile);
  // const isLoadingProfle = useSelector(isLoadingProfile)
  // let userProfile = profileData?.payload;
  // let loginId = userProfile?.id;
  const selectLoginUser = useSelector(selectLoginProfile);
    let loginId = selectLoginUser?.payload?.id;

  let fullname = `${selectProfile?.payload?.user_profiles?.firstname} `;
  useEffect(() => {
    let params = {
      loginId: loginId,
      token: selectLoginUser.payload.token
    }                
    dispatch(userProfileAsync(params))
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2 profile-header">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          My Profile{" "}
        </h4>
        <div className="edit-btn">
          <Link
            to="/dashboard/my-profile-edit"
          >
            <img src={Edit} alt="" /> Edit
          </Link>
        </div>
      </div>

      <div className="profile-main">
        <div className="profile-image">
          <Link to="#">
            <img src={selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : Profile} alt="Profile" />
          </Link>
        </div>

        <div className="profile-box">
          <div className="profbox-inner">
            <h4>{selectProfile?.payload?.rating}</h4>
            <p>Rating</p>
          </div>
          <div className="profbox-inner">
            <h4>
              <span>{selectProfile?.payload?.deliveries}</span>
            </h4>
            <p>Deliveries</p>
          </div>
        </div>

        <div className="profile-info">
          <p>Name</p>
          <h4>
            <img src={UserBlue} alt="" /> {fullname ? fullname : "dummyUserName"}
          </h4>
          <p>Email address</p>
          <h4>
            <img src={EmailBlue} alt="" /> {selectProfile?.payload?.email ? selectProfile?.payload?.email : ""}
          </h4>
          <p>Phone number </p>
          <h4>
            <img src={Tell} alt="" /> {selectProfile?.payload?.user_profiles ? selectProfile?.payload?.user_profiles.full_phone_number : ""}{" "}
            <img className="verify-grn" src={VerifyGreen} alt="" />
          </h4>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
