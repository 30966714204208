import React, { useState, useEffect } from "react";
import {  Link } from "react-router-dom";
import { toast } from "react-toastify";
import Verify from "../../../../public/images/verify.svg";
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryDetailsAsync, selectDeliveryData } from "../../deliverySlice"
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import CustomModal from "../../../../components/shared/CustomModal"

const VerifyDelivery = (props) => {
  const dispatch = useDispatch()
  const deliveryData = useSelector(selectDeliveryData)

  const [otp, setOtp] = useState("");

  const [modalDetail, setModalDetail] = useState({
    show: false
  });
  const [modalKey, setModalKey] = useState(Math.random());

  const handleChange = (otp) => {
    setOtp(otp);
  }

  const handleVerify = () => {
    if (!otp || otp.length < 5) {
      toast.error("Please enter your Otp");
      return false
    }
    setModalDetail({ show: true });
    setModalKey(Math.random());
  }

  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setModalKey(Math.random());
  }

  useEffect(() => {
    dispatch(getDeliveryDetailsAsync())
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
            width="573"
            height="700"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />{" "}
        </div>
        <div className="pickup-pointbox">
          <div className="deliver-verifybox">
            <h2>Verify Delivery</h2>
            <p>
              Ask for Order code from shop and <br /> verify it
            </p>
            <div className="verify-box d-flex justify-content-center">
              <OTPInput className='input_digits_'
                value={otp}
                numInputs={5}
                isInputNum={true}
                data-cy="pin-field"
                onChange={handleChange}
              // onChange ={() => {}} 
              // onComplete={(code) => onComplete(code)}
              />
            </div>
          </div>
          <div className="pickup-mbtn pickup-bluebtn">
            <Link to="#" onClick={handleVerify}>
              Verify
            </Link>
          </div>
        </div>
      </div>

      <CustomModal
        key={modalKey}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        showCloseBtn={true}
        child={
          <div className="modal-body">
            <div className="modal-mainprt modal-boxinfo">
              <div className="mdlverify-cnt">
                <h4>Verified</h4>
                <img src={Verify} alt="" />
                <p>You have completed delivery!</p>
                <h6>You received fee ${deliveryData?.eta}.00</h6>
              </div>
              <div className="popup-btn2">
                <ul>
                  <li>
                    <Link to="#" onClick={handleOnCloseModal}>
                      Cancel
                    </Link>
                  </li>
                  <li>
                    <Link to='/dashboard/wallet' className="active">
                      {props?.location?.state?.data === "1hourdelivery" ? "Thanks" : "Wallet"}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        }
        // size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />

    </>
  );
};

export default VerifyDelivery;