import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../public/images/logo.svg";
import LockDark from "../../../public/images/lock-dark.png";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2'
import { registerOtpSendAsync, selectOtpId } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";


const RegisterPhone = (props) => {
  const history = useHistory();
  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");
  const [phoneLoading, setPhoneLoading] = useState(false);
  const dispatch = useDispatch()
  const toastId = React.useRef(null)

  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  }
  let value = {
    "phone_code": `+${phoneCode}`,
    "phone_no": phoneNo
  }
  const otpId = useSelector(selectOtpId);

  const sendOtp = async () => {
    if (!phoneNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter phone number");
      }
      return;
    }
    else if (phoneNo && phoneNo.length < 10) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Phone number length should be of 10 digits");
      }
      return;
    }
    else if (phoneNo && !phoneCode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select your phone code");
      }
      return;
    }
    localStorage.setItem("phonedetails", JSON.stringify(value))
    setPhoneLoading(true)
    await dispatch(registerOtpSendAsync(value))
      .then(unwrapResult)
      .then((obj) => {
        setPhoneLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        if (obj?.payload?.is_phone_exits) {
          history.push('/login')
        } else {
          history.push('/verify-phone-on-Register')
        }
      }
      )
      .catch((obj) => {
        setPhoneLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  }

  return (
    <>
      <main className="main-wrapper">
        <div className="logo-part">
          <Link to="#">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="lock-part reglock-prt">
          <img src={LockDark} alt="" />
        </div>

        <div className="regmain-part">
          <h2>
            Continue with your <strong>phone number</strong>
          </h2>
          <p>Please enter your number to continue</p>
          <div className="country-plugin">
            <form action="#" name="POST">
              <div id="result">
                <PhoneInput
                  country="us"
                  // value={phoneNo}
                  enableSearch={true}
                  placeholder="Phone no."
                  onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                />
                <span id="valid-msg" className="hide">✓ Valid</span>
                <span id="error-msg" className="hide"></span>
              </div>
            </form>

            <div className="bottom-part">
              <button className="bottom-btn continue" disabled={phoneLoading === true} onClick={() => { sendOtp() }}>
                {phoneLoading === true && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                Continue</button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default RegisterPhone;