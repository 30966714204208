import axios from "axios";
import getDeliveryData from "../../db/way-to-delivery.json";
import getVerfifyPickup from "../../db/way-to-pickup.json"
import getReturn from "../../db/way-to-return.json"
// A mock function to mimic making an async request for data
export function getDeliveryDetails() {
    return getDeliveryData
}
export function getVerifyPickupData() {
    return getVerfifyPickup
}
export function getReturnData() {
    return getReturn
}