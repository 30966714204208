import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Routes from "./routes";

const App = () => {
  
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        // limit={1}
        // pauseOnHover
      />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
    </>
  );
}

export default App;
