import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getProfilePhoto, getUserProfile,
    getUpdateProfile
} from "./sharedApi";

const initialState = {
    profile: null,
    profilePhoto: null,
    userProfile: null,
    status: "idle",
    status1: {
        userProfile: "idle"
    }
};

export const profilePhotoAsync = createAsyncThunk("profile/photo", async (data) => {
    const response = await getProfilePhoto(data);
    return response.data;
});

export const updateProfileAsync = createAsyncThunk("update/profile", async (data) => {
    const response = await getUpdateProfile(data);
    return response.data;
});
export const userProfileAsync = createAsyncThunk("user/profile", async (data) => {
    const response = await getUserProfile(data);
    return response.data;
});

export const sharedSlice = createSlice({
    name: "profile",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(profilePhotoAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(profilePhotoAsync.fulfilled, (state, { payload }) => {
                state.profilePhoto = payload
                state.status = "idle";
            })
            .addCase(profilePhotoAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.profilePhoto = null
            })
            .addCase(updateProfileAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateProfileAsync.fulfilled, (state, { payload }) => {
                state.updateprofile = payload
                state.status = "idle";
            })
            .addCase(updateProfileAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.updateprofile = null
            })
            .addCase(userProfileAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(userProfileAsync.fulfilled, (state, { payload }) => {
                state.userProfile = payload
                state.status = "idle";
            })
            .addCase(userProfileAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.userProfile = null
            })
    },
});

export const selectProfileData = (state) => state.shared.profile;
export const selectProfilePhoto = (state) => state.editProfilePhoto.profilePhoto;
export const selectUserProfile = (state) => state.shared.userProfile;
export default sharedSlice.reducer;