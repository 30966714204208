import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import LeftArrow from "../../public/images/left-arrow.svg";
import Clock from "../../public/images/clock.svg";
import Location from "../../public/images/location.svg";
import Timeline from "../../public/images/timeline-prof.png";
import { useSelector, useDispatch } from "react-redux";
import { getdeliveryRequestApiAsync, getdeliveryRequestDeliveredApiAsync } from "./dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonLoader from "../../components/shared/CommonLoader";
import moment from 'moment';

const MyDeliveries = (props) => {
  const history = useHistory()
  const [pendingFlag, setPendingFlag] = useState(true);
  const [deliveredFlag, setDeliveredFlag] = useState(false);
  const [returnedFlag, setReturnedFlag] = useState(false);
  const [deliveredData, setDeliveredData] = useState([])
  const [pendingData, setPendingData] = useState([])
  const [returnedData, setReturnedData] = useState([])
  const [loading, setLoading] = useState(false)
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()
  // let date = moment(enterprisesDate).format('LT')
  moment().format('LT');  


  const changeSelection = (e, flag) => {
    e.preventDefault();
    if (flag === "pending") {
      setPendingFlag(true);
      setDeliveredFlag(false);
      setReturnedFlag(false);
    } else if (flag === "delivered") {
      setPendingFlag(false);
      setDeliveredFlag(true);
      setReturnedFlag(false);
    } else if (flag === "returned") {
      setPendingFlag(false);
      setDeliveredFlag(false);
      setReturnedFlag(true);
    }
  };

  useEffect(() => {
    setLoading(true)
    let deliveredParams = {
      postData: {
        driver_id: auth?.payload?.uuid,
        status: "4"
      },
      tokenData: auth?.payload?.token
    }
    dispatch(getdeliveryRequestApiAsync(deliveredParams))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setDeliveredData(obj?.data?.payload?.data)
        console.log(obj?.data?.payload?.data, "response of delivered")
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
    setLoading(true)
    let pendingParams = {
      postData: {
        driver_id: auth?.payload?.uuid,
        status: "1,3"
      },
      tokenData: auth?.payload?.token
    }
    dispatch(getdeliveryRequestDeliveredApiAsync(pendingParams))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setPendingData(obj?.data?.payload?.data)
        console.log(obj?.data?.payload?.data, "response of pending")
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
    setPendingFlag(true);
    setDeliveredFlag(false);
    setReturnedFlag(false);
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            {" "}
            <img src={LeftArrow} alt="" />{" "}
          </Link>{" "}
          My Deliveries
        </h4>
      </div>

      {
        loading ?
          <CommonLoader /> :
          <div className="support-main">
            <div className="tabs">
              <div className="tab-upper delv-tabupr">
                <ul className="tab-links">
                  <li className={pendingFlag ? "active" : ""}>
                    <Link to="#" onClick={(e) => changeSelection(e, "pending")}>
                      Pending ({pendingData?.length ? pendingData?.length : 0})
                    </Link>
                  </li>
                  <li className={deliveredFlag ? "active" : ""}>
                    <Link to="#" onClick={(e) => changeSelection(e, "delivered")}>
                      Delivered ({deliveredData?.length ? deliveredData?.length : 0})
                    </Link>
                  </li>
                  <li className={returnedFlag ? "active" : ""}>
                    <Link to="#" onClick={(e) => changeSelection(e, "returned")}>
                      Returned ({returnedData?.length ? returnedData?.length : 0})
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="tab-content">
                {pendingFlag && (
                  <>
                    <div id="tab1" className="tab active">
                      {pendingData?.length > 0 ? (
                        <>
                          {pendingData?.map(
                            (pendingValue, index) => {
                              // let now = moment(pendingValue?.order?.status_desc?.status_2_updated_at).format('YYYY-MM-DD, h:mm:ss a');
                              // let then = moment(pendingValue?.order?.status_desc?.status_1_updated_at).format('YYYY-MM-DD, h:mm:ss a');
                              // console.log(now,"pendingValue");
                              let start = moment(moment(pendingValue?.order?.status_desc?.status_2_updated_at).format('YYYY-MM-DD, h:mm:ss a'), 'YYYY-MM-DD HH:mm:ss');
                              let end = moment(moment(pendingValue?.order?.status_desc?.status_1_updated_at).format('YYYY-MM-DD, h:mm:ss a'), 'YYYY-MM-DD HH:mm:ss');
                              const duration = moment.duration(start.diff(end));
                              console.log(duration,"duration");
                              return (
                                <React.Fragment key={index}>
                                  <div>
                                    <div
                                      className="support-rewtab1 pt-4"
                                      id={index}
                                    >
                                      <h2 style={{cursor:"pointer"}} onClick={()=> { history.push({ pathname: '/dashboard/my-deliveries-history', state: pendingValue })}}>
                                        #{pendingValue.driver_id} <span>Pending</span>
                                      </h2>
                                      <div className="tab1-pendinr">
                                      {pendingValue?.status == "2" ?
                                        <> 
                                        <span>
                                          <img src={Clock} alt="" />{" "}
                                          <span>
                                          D: {duration?._data?.days}
                                        </span>
                                        <span>                                         
                                          H: {duration?._data?.hours}
                                        </span>
                                        <span>                                          
                                          M: {duration?._data?.minutes}
                                        </span>
                                        </span>
                                        </> : ""  }
                                        <span>
                                          <img src={Location} alt="" />{" "}
                                          12 km
                                        </span>                                        
                                      </div>

                                      <div className="verticle-timeline">
                                        <ul>
                                          <li className={pendingValue?.status == "2" ? "check" : ""}>
                                            <h4>Pickup point</h4>
                                            <p>{pendingValue?.order_pick_address}</p>
                                          </li>
                                          <li>
                                            <h4 className="dark-thd">
                                              Delivery point
                                            </h4>
                                            <div className="timeline-prof">
                                            <img src={pendingValue?.user_info?.user_profiles?.profile_photo ? pendingValue?.user_info?.user_profiles?.profile_photo :Timeline}/>                                            <div className="timeline-profcnt">
                                                <h2>
                                                {`${pendingValue?.user_info?.user_profiles?.firstname} ${pendingValue?.user_info?.user_profiles?.lastname}`}
                                                </h2>
                                                <p>
                                                  {pendingValue?.order_delivery_address}
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className="horizental-timeline">
                                        <ul>
                                        <li className={pendingValue?.status >= "2" ? "active" : ""}>Picked</li>
                                        <li className={pendingValue?.status === "3" ? "active" : ""}>Delivered</li>
                                        <li className={pendingValue?.status > "3" ? "verify-dark" : ""}>Verified</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>No Data Found</>
                      )}
                    </div>
                  </>
                )}

                {deliveredFlag && (
                  <>
                    <div id="tab2" className="tab active">
                      {deliveredData ? (
                        <>
                          {deliveredData?.map(
                            (pendingValue, index) => {
                              let start = moment(moment(pendingValue?.order?.status_desc?.status_3_updated_at).format('YYYY-MM-DD, h:mm:ss a'), 'YYYY-MM-DD HH:mm:ss');
                              let end = moment(moment(pendingValue?.order?.status_desc?.status_1_updated_at).format('YYYY-MM-DD, h:mm:ss a'), 'YYYY-MM-DD HH:mm:ss');
                              const duration = moment.duration(start.diff(end));
                              return (
                                <React.Fragment key={index}>
                                  <div>
                                    <div
                                      className="support-rewtab1 pt-4"
                                      id={index}
                                    >
                                      <h2 style={{cursor:"pointer"}} onClick={()=> { history.push({ pathname: '/dashboard/my-deliveries-history', state: pendingValue })}}>
                                        #{pendingValue.driver_id} <span>Delivered</span>
                                      </h2>
                                      <div className="tab1-pendinr">
                                      {pendingValue?.status == "3" ?
                                       <>
                                        <span>
                                          <img src={Clock} alt="" />{" "}
                                          <span>
                                          D: {duration?._data?.days}
                                        </span>
                                        <span>
                                          H: {duration?._data?.hours}
                                        </span>
                                        <span>
                                          M: {duration?._data?.minutes}
                                        </span>
                                        </span>
                                        </> : ""}
                                        <span>
                                          <img src={Location} alt="" />{" "}
                                          {pendingValue?.distance} km
                                        </span>
                                      </div>

                                      <div className="verticle-timeline">
                                        <ul>
                                          <li className={pendingValue?.status >= "2" ? "check" : ""}>
                                            <h4>Pickup point</h4>
                                            <p>{pendingValue?.order_pick_address}</p>
                                          </li>
                                          <li className={pendingValue?.status === "4" ? "check" : ""}>
                                            <h4 className="dark-thd">
                                              Delivery point
                                            </h4>
                                            <div className="timeline-prof">
                                              <img src={pendingValue?.user_info?.user_profiles?.profile_photo ? pendingValue?.user_info?.user_profiles?.profile_photo :Timeline} alt="" />
                                              <div className="timeline-profcnt">
                                                <h2>
                                                  {`${pendingValue?.user_info?.user_profiles?.firstname} ${pendingValue?.user_info?.user_profiles?.lastname}`}
                                                </h2>
                                                <p>
                                                  {pendingValue?.order_delivery_address}
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className="horizental-timeline">
                                        <ul>
                                          <li className={pendingValue?.status >= "2" ? "active" : ""}>Picked</li>
                                          <li className={pendingValue?.status === "4" ? "active" : ""}>Delivered</li>
                                          <li className={pendingValue?.customer_otp_status ? "active" : ""}>Verified</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>No Data Found</>
                      )}
                    </div>
                  </>
                )}

                {returnedFlag && (
                  <>
                    <div id="tab3" className="tab active">
                      {returnedData?.length > 0 ? (
                        <>
                          {deliveredData?.map(
                            (pendingValue, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <div>
                                    <div
                                      className="support-rewtab1 pt-4"
                                      id={index}
                                    >
                                      <h2 style={{cursor:"pointer"}} onClick={()=> { history.push({ pathname: '/dashboard/my-deliveries-history', state: pendingValue })}}>
                                        #{pendingValue.driver_id} <span>Pending</span>
                                      </h2>
                                      <div className="tab1-pendinr">
                                        <span>
                                          <img src={Clock} alt="" />{" "}
                                          11 min
                                        </span>
                                        <span>
                                          <img src={Location} alt="" />{" "}
                                          12 km
                                        </span>
                                      </div>

                                      <div className="verticle-timeline">
                                        <ul>
                                          <li className="check">
                                            <h4>Pickup point</h4>
                                            <p>{pendingValue?.order_pick_address}</p>
                                          </li>
                                          <li className="check">
                                            <h4 >
                                              Delivery point
                                            </h4>
                                            <div className="timeline-prof">
                                              <img src={Timeline} alt="" />
                                              <div className="timeline-profcnt">
                                                <h2>
                                                  abc
                                                </h2>
                                                <p>
                                                  {pendingValue?.order_delivery_address}
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>

                                      <div className="horizental-timeline">
                                        <ul>
                                          <li className="active">Picked</li>
                                          <li>Delivered</li>
                                          <li className="verify-dark">Verified</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          )}
                        </>
                      ) : (
                        <>No Data Found</>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
      }

    </>
  );
};

export default MyDeliveries;