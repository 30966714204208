import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import LeftArrow from "../../public/images/left-arrow.svg";
import Rider from "../../public/images/rider.png";
import DistanceLine from "../../public/images/distance-line.png";
import Fuel from "../../public/images/fuel.png";
import Money from "../../public/images/money-16-regular.png";
import { useSelector, useDispatch } from "react-redux";
import { getAppTrackerDataApiAsync, selectAppTrackerData } from "./dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import { useState } from "react";
import CommonLoader from "../../components/shared/CommonLoader";
import { unwrapResult } from "@reduxjs/toolkit";

const AppTracker = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const auth = useSelector(selectLoginAuth)
  const trackerData = useSelector(selectAppTrackerData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAppTrackerDataApiAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/express-pickup-request");
            }}
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          App tracker
        </h4>
      </div>

      {
        loading ?
          <CommonLoader /> :
          <div className="apptrack-main">
            {
              trackerData?.data?.payload?.map((val, index) => {
                return (
                  <div className="apptrack-box" key={index}>
                    <Link
                      to="#"
                      className={val?.title === "Total Trip" ? "" : val?.title === "Total Distance" ? "bg2" : val?.title === "Total Fuel Usages" ? "bg3" : "bg4"}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(val?.title === "Total Trip" ? "/dashboard/app-tracker-list" : "#");
                      }}
                    >
                      <span className="apptrack-item">
                        <img src={val?.title === "Total Trip" ? Rider : val?.title === "Total Distance" ? DistanceLine : val?.title === "Total Fuel Usages" ? Fuel : Money} alt="" />
                        <span className="apptrack-cnt">
                          <p>
                            {val?.title} <i className="fas fa-chevron-right"></i>
                          </p>
                          <h2>{val?.total ?val?.title === "Total Distance" ? `${val?.total}Km` : val?.title === "Total Fuel Usages" ? `${val?.total}L` : val?.title === "Total Cost" ? `$${val?.total}` : val?.total : 0}</h2>
                        </span>
                      </span>
                      <h6>
                        Today:{" "}
                        <strong>{val?.today ? val?.today : 0}</strong>
                      </h6>
                    </Link>
                  </div>
                )
              })
            }
          </div>
      }

    </>
  );
};

export default AppTracker;