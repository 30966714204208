import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LogoIcon from "../../public/images/logo-icon_2.svg";
import Timeline from "../../public/images/timeline-prof.png";
import { useSelector, useDispatch } from "react-redux";
import { changeDeliveryStatusApiAsync } from "./dashbaordSlice"
import Shop from "../../public/images/shop.png";
import { selectLoginAuth } from "../auth/authSlice";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import io from 'socket.io-client';
import { unwrapResult } from "@reduxjs/toolkit";
import downArrow from "../../public/images/downArrow.svg"
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
} from '@react-google-maps/api'
import { getAddressFromCoordinates } from "../auth/register/AddressMethod";
import moment from "moment-timezone";

const PickupRequest = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [libraries] = useState(['places']);
  const [loading, setLoading] = useState(false)
  const [requestedArray, setRequestsArray] = useState("")
  const loginAuth = useSelector(selectLoginAuth)

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [driverDistance, setDriverDistance] = useState("")
  const [driverDuration, setDriverDuration] = useState("")
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries,
  })

  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(position?.coords?.latitude, position?.coords?.longitude)
        .then(data => {
          calculateDriverRoute(data?.formatted_address)
        })
        .catch(err => console.log('error getting address', err));
    });
  }

  const center = { lat: 30.6574932, lng: 76.727102 }

  async function calculateRoute() {
    if (requestedArray?.order_pick_address === '' || requestedArray?.order_delivery_address === '' || !requestedArray) {
      return false
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: requestedArray?.order_pick_address,
      destination: requestedArray?.order_delivery_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    // setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  async function calculateDriverRoute(driver) {
    if (driver == '' || requestedArray?.order_pick_address == undefined) {
      return false
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: driver,
      destination: requestedArray?.order_pick_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
    setDriverDistance(results?.routes[0]?.legs[0]?.distance?.text)
    setDriverDuration(results?.routes[0]?.legs[0]?.duration?.text)
  }

  const handleRequestCancel = async () => {
    setRequestsArray([])
  }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer"></div>;
    }
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  }
  const handleAccept = async (data) => {
    setLoading(true)
    let data1 = {
      "postData": {
        "order_id": `${requestedArray?.order_id}`,
        "status": "1"
      },
      "tokenData": loginAuth?.payload?.token
    }
    localStorage.setItem("requesteddata", JSON.stringify(requestedArray))
    dispatch(changeDeliveryStatusApiAsync(data1))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.data?.msg)
        }
        if(obj?.data?.status_code == 202){
          setRequestsArray([])
        }else{
          history.push({ pathname: '/dashboard/express-delivery/pickup/way-to-pickup', state: JSON.stringify(data) })
        }
       
      }
      )
      .catch((obj) => {
        setLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })

  }

  const getDeliveryRequest = async () => {
    const socket = io(`${process.env.REACT_APP_ORDER_URL}`, { path: '/api/v1/connect' });

    socket.emit("setDriverLocation", {
      driver_uid: loginAuth?.payload?.uuid,
      latitude: 30.7333,
      longitude: 76.7794
    })
    socket.emit("setSocketId", { user_uid: loginAuth?.payload?.uuid });
    socket.on("orderNotification", (data) => {
      setRequestsArray(data)
      // setShowRequests(true)
    })
  }
  const [middlebody, setmiddlebody] = useState(false);
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };

  var travelTime = moment().add((Number(driverDuration.split(" ")[0]) + Number(duration.split(" ")[0])), 'minutes').format('hh:mm A');

  console.log(requestedArray, "requested array")
  useEffect(() => {
    getDeliveryRequest()
    calculateRoute()
    geocodeHandler()
  }, [requestedArray]);

  return (
    <>
      <div className="dlvpickup-main">
        {/* <div className="reqaccpt-exprs text-center delvpop-upbtnUpdated">
          <span>
            1 hour window delivery: <strong>0</strong>
          </span>
        </div> */}

        <div className="MapOrders">
          <div className="MapOrders_inner">
            {
              isLoaded ?
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={map => setMap(map)}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap> : <></>
            }

          </div>
          {" "}
        </div>

        <div className="track-lcitem track-lctdl3">
          {/* <img src={Location} alt="" /> */}
        </div>
        <div className="pickup-pointbox">
          {/* <h3 className="midContent_">
        You can accept 2 delivery request
        </h3> */}
          <div className="modal-body">
            {/* <div className="delvpop-upbtn">
                <span>
                  1 hour window delivery: <strong>0</strong>
                </span>
              </div> */}
            {requestedArray ?
              <div className="modal-mainprt">
                <div className="accept-mdlbox windlv-1hour">
                  <div className="accept-mdlupper dlvacpt-hrwind" onClick={() => handleClick(true)}>
                    <h2 className="highlightSubhead_">
                      <p className="textInner_">
                        {requestedArray?.delivery_type?.title}
                      </p>
                      <span>New delivery request</span>
                    </h2>
                    <div className="timer-wrapper">
                      <div className="timer">
                        <CountdownCircleTimer
                          isPlaying
                          duration={30}
                          colors={"#1FB3FF"}
                          onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                          size={58}
                          strokeWidth={8}
                        >
                          {renderTime}
                        </CountdownCircleTimer>
                      </div>
                    </div>
                    <img className="dropArrow" src={downArrow} alt="" />
                  </div>
                  <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
                    <div className="accptmdl-uprtimeline accptmdl-uprtimelineUpdated">
                      <ul>
                        <li>
                          <div className="tmmin-cnt">
                            <p>
                              {driverDuration} <span>{driverDistance}</span>
                            </p>
                          </div>
                          <div className="logot-icon2">
                            <img src={LogoIcon} alt="" />
                          </div>
                        </li>
                        <li>
                          <div className="tmmin-cnt">
                            <p>
                              {duration} <span>{distance}</span>
                            </p>
                          </div>
                          <div className="shop-tml">
                            <img src={requestedArray?.seller_info?.user_profiles?.profile_photo ? requestedArray?.seller_info?.user_profiles?.profile_photo : Shop} alt="" style={{ width: "20px", height: "20px", borderRadius: "50px" }} />
                          </div>
                        </li>
                        <li>
                          <div className="prof-tml2 prof-tml2Updated">
                            <img src={requestedArray?.user_info?.user_profiles?.profile_photo ? requestedArray?.user_info?.user_profiles?.profile_photo : Timeline} alt="" />
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="accept-mdltimline">
                      <div className="verticle-timeline">
                        <ul>
                          <li>
                            <h4>
                              Pickup point <span> with in next {driverDuration}</span>
                            </h4>
                            {/* <p>1189 Main Street, Seattle, WA 98161</p> */}
                            <p>{requestedArray?.order_pick_address}</p>
                          </li>
                          <li className="dark-cnt">
                            <h4 className="dark-thd">
                              Delivery point <span>By {travelTime}</span>
                            </h4>
                            {/* <p>1189 Main Street, Seattle, WA 98161</p> */}
                            <p>{requestedArray?.order_delivery_address}</p>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <ul className="mdlbtm-links">
                      <li onClick={() => handleRequestCancel()}>
                        <Link to="#">
                          Cancel
                        </Link>
                      </li>
                      <li>
                        {/* <Link to="#" className="accept-btn darkBg">
                          Accept
                        </Link> */}
                        <button className="accept-btn darkBg" disabled={loading === true} onClick={() => handleAccept(requestedArray)}>
                          {loading === true && (
                            <span className="spinner-border spinner-border-sm"></span>
                          )}
                          &nbsp;&nbsp;
                          Accept</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              : ""}
          </div>
        </div>
      </div>
    </>
  );
};

export default PickupRequest;