import axios from "axios";
import getTrackData from "../../db/app-tracker.json";
import getMyDelivery from "../../db/my-deliveries.json"
import getSupportDetailsData from "../../db/support-details.json"
import { toast } from "react-toastify";

const queryString = require('query-string');
// A mock function to mimic making an async request for data
export function getAppTrackData() {
  return getTrackData
}
export function getMyDeliveryData() {
  return getMyDelivery
}
export function getSupportDetails() {
  return getSupportDetailsData
}
export function getRewardData(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/rewards/subscription`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getSupportApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(data ? `${process.env.REACT_APP_SUPPORT}api/v1/supports/user` + "?" + query : `${process.env.REACT_APP_SUPPORT}api/v1/supports/user`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getTransactionHistory(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_STAGING_WALLET_URL}api/v1/transactions/user` + "?" + query, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getWalletData(token) {
  return new Promise((resolve, reject) =>
      axios
          .get(`${process.env.REACT_APP_STAGING_WALLET_URL}api/v1/wallets/balance`, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token,
                  'app-name': 'b2c'
              }
          })
          .then(function (response) {
              resolve(response);
          })
          .catch(function (error) {
              reject(error)
              console.error(error);
          })
  )
}

export function getSubjectApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_SUPPORT}api/v1/subjects?page=1&limit=10`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function supportDocumentUrlApi(data) {
  var formData = new FormData();
  formData.append('document', data.document);
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_SUPPORT}api/v1/supports/document`, formData, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function redeemSilaApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_STAGING_WALLET_URL}api/v1/transactions/redeem`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function sendMessageToCustomer(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/messages`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getMessageList(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/messageheads/${data}`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getMessageListHead(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/messageheads`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function createSupportRequestApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_SUPPORT}api/v1/supports`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.msg)
        reject(error)
      })
  )
}

export function getdeliveryRequestApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery` + "?" + query, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getNotificationApi(data,token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/notifications`+"?"+query, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function readNotificationApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_USER_SERVICES}api/v1/notifications/read`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function VerifyOrderDeliveryOtpApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/verify-otp`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function VerifyDeliveryOtpApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/verify-otp`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function VerifyDeliveryCancelOtpApi(params, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/cancel-order/verify-otp`, params, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function getChecklistApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/check_list`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getRideHistoryApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/rewards/history`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getAppTrackerDataApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/driver/statistics`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getTripDataApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery?driver_id=${data}`, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function addFuelBillApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/fuel_bills/bulk-create`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function changeDeliveryStatusApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/change-status`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}

export function getUserSettingApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_settings` + "?" + query, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}

export function updateUserSettingApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .patch(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_settings`, data, {
        headers: {
          'app-name': 'driver',
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
      })
  )
}