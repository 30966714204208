import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAppTrackData,
    getMyDeliveryData,
    getRewardData,
    getSupportDetails,
    getSupportApi,
    getSubjectApi,
    supportDocumentUrlApi,
    createSupportRequestApi,
    getdeliveryRequestApi,
    VerifyOrderDeliveryOtpApi,
    VerifyDeliveryOtpApi,
    VerifyDeliveryCancelOtpApi,
    getChecklistApi,
    getRideHistoryApi,
    getAppTrackerDataApi,
    getTripDataApi,
    addFuelBillApi,
    changeDeliveryStatusApi,
    getUserSettingApi,
    updateUserSettingApi,
    getTransactionHistory,
    getWalletData,
    redeemSilaApi,
    getNotificationApi,
    readNotificationApi,
    sendMessageToCustomer,
    getMessageList,
    getMessageListHead
} from "./dashboardApi";

const initialState = {
    track: null,
    mydelivery: null,
    reward: null,
    supportdetails: null,
    support: [],
    wallet: null,
    subject: [],
    supportImage: null,
    supportCreate: [],
    deliveryRequest: [],
    orderVerifyOtp: null,
    deliveryVerifyOtp: null,
    deliveryCancelOtp: null,
    deliveryCancelledOtp: null,
    deliveryDelivered: [],
    deliveryReturned: [],
    checkList: [],
    rideHistory: [],
    appTracker: [],
    tripData: [],
    fuelBill: [],
    deliveryStatus: null,
    transectionHistory: [],
    walletBalance: [],
    status: {
        track: 'idle',
        mydelivery: 'idle',
        reward: 'idle',
        supportdetails: 'idle',
        support: 'idle',
        wallet: 'idle',
        subject: 'idle',
        supportImage: 'idle',
        supportCreate: 'idle',
        deliveryRequest: 'idle',
        orderVerifyOtp: 'idle',
        deliveryVerifyOtp: 'idle',
        deliveryCancelOtp: 'idle',
        deliveryCancelledOtp: 'idle',
        deliveryDelivered: 'idle',
        deliveryReturned: 'idle',
        checkList: 'idle',
        rideHistory: 'idle',
        appTracker: 'idle',
        tripData: 'idle',
        fuelBill: 'idle',
        deliveryStatus: 'idle',
        transectionHistory: "idle",
        walletBalance: "idle",
    },
};

export const getTrackDataAsync = createAsyncThunk("dashboard/track", async (data) => {
    const response = await getAppTrackData();
    return response;
});
export const getMyDeliveryAsync = createAsyncThunk("dashboard/mydelivery", async (data) => {
    const response = await getMyDeliveryData();
    return response;
});
export const getRewardAsync = createAsyncThunk("dashboard/reward", async (data) => {
    const response = await getRewardData(data);
    return response;
});
export const getSupportDetailsAsync = createAsyncThunk("dashboard/supportdetails", async (data) => {
    const response = await getSupportDetails();
    return response;
});
export const getSupportApiAsync = createAsyncThunk("dashboard/supportlist", async (data) => {
    const response = await getSupportApi(data.postData, data.tokenData);
    return response;
});
export const getSubjectApiAsync = createAsyncThunk("dashboard/subject", async () => {
    const response = await getSubjectApi();
    return response;
});
export const supportApiUrlAsync = createAsyncThunk("dashboard/spporturl", async (data) => {
    const response = await supportDocumentUrlApi(data);
    return response;
});
export const supportCreateApiAsync = createAsyncThunk("dashboard/supportcreate", async (data) => {
    const response = await createSupportRequestApi(data.postData, data.tokenData);
    return response;
});
export const getdeliveryRequestApiAsync = createAsyncThunk("dashboard/deliveryrequest", async (data) => {
    const response = await getdeliveryRequestApi(data.postData, data.tokenData);
    return response;
});
export const getdeliveryRequestDeliveredApiAsync = createAsyncThunk("dashboard/deliveryrequest1", async (data) => {
    const response = await getdeliveryRequestApi(data.postData, data.tokenData);
    return response;
});
export const VerifyOrderDeliveryOtpApiAsync = createAsyncThunk("dashboard/verifyorderotp", async (data) => {
    const response = await VerifyOrderDeliveryOtpApi(data.postData, data.tokenData);
    return response;
});
export const VerifyDeliveryOtpApiAsync = createAsyncThunk("dashboard/verifydeliveryotp", async (data) => {
    const response = await VerifyDeliveryOtpApi(data.postData, data.tokenData);
    return response;
});
export const VerifyDeliveryCancelOtpApiAsync = createAsyncThunk("dashboard/VerifyDeliveryCancelOtpApiAsync", async (data) => {
    const response = await VerifyDeliveryCancelOtpApi(data.postData, data.tokenData);
    return response;
})
export const getChecklistApiAsync = createAsyncThunk("dashboard/getChecklistApi", async (data) => {
    const response = await getChecklistApi(data);
    return response;
})
export const getRideHistoryApiAsync = createAsyncThunk("dashboard/getRideHistoryApiAsync", async (data) => {
    const response = await getRideHistoryApi(data);
    return response;
})
export const getAppTrackerDataApiAsync = createAsyncThunk("dashboard/getAppTrackerDataApi", async (data) => {
    const response = await getAppTrackerDataApi(data);
    return response;
})
export const getTripDataApiAsync = createAsyncThunk("dashboard/getTripDataApiAsync", async (data) => {
    const response = await getTripDataApi(data.postData, data.tokenData);
    return response;
})
export const addFuelBillApiAsync = createAsyncThunk("dashboard/addFuelBillApiAsync", async (data) => {
    const response = await addFuelBillApi(data.postData, data.tokenData);
    return response;
})
export const changeDeliveryStatusApiAsync = createAsyncThunk("dashboard/changeDeliveryStatusApi", async (data) => {
    const response = await changeDeliveryStatusApi(data.postData, data.tokenData);
    return response;
})
export const getUserSettingApiAsync = createAsyncThunk("dashboard/getUserSettingApi", async (data) => {
    const response = await getUserSettingApi(data.postData, data.tokenData);
    return response;
})
export const updateUserSettingApiAsync = createAsyncThunk("dashboard/updateUserSettingApi", async (data) => {
    const response = await updateUserSettingApi(data.postData, data.tokenData);
    return response;
})
export const getTransectionHistoryAsync = createAsyncThunk("wallet/getTransectionHistory", async (data) => {
    const response = await getTransactionHistory(data.postData, data.tokenData);
    return response;
});
export const getWalletDataAsync = createAsyncThunk("wallet/getWalletData", async (data) => {
    const response = await getWalletData(data);
    return response;
});
export const redeemSilaApiAsync = createAsyncThunk("wallet/redeemSilaApi", async (data) => {
    const response = await redeemSilaApi(data.postData, data.tokenData);
    return response;
});
export const getNotificationApiAsync = createAsyncThunk("wallet/getNotificationApi", async (data) => {
    const response = await getNotificationApi(data.postData,data.tokenData);
    return response;
});
export const readNotificationApiAsync = createAsyncThunk("wallet/readNotificationApi", async (data) => {
    const response = await readNotificationApi(data.postData, data.tokenData);
    return response;
});
export const sendMessageToCustomerAsync = createAsyncThunk("wallet/sendMessageToCustomer", async (data) => {
    const response = await sendMessageToCustomer(data.postData, data.tokenData);
    return response;
});
export const getMessageListAsync = createAsyncThunk("wallet/getMessageList", async (data) => {
    const response = await getMessageList(data.postData, data.tokenData);
    return response;
});
export const getMessageListHeadAsync = createAsyncThunk("wallet/getMessageListHead", async (data) => {
    const response = await getMessageListHead(data);
    return response;
});

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getTrackDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTrackDataAsync.fulfilled, (state, { payload }) => {
                state.track = payload
                state.status = "idle";
            })
            .addCase(getTrackDataAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.track = null
            })
            .addCase(getMyDeliveryAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getMyDeliveryAsync.fulfilled, (state, { payload }) => {
                state.mydelivery = payload
                state.status = "idle";
            })
            .addCase(getMyDeliveryAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.mydelivery = null
            })
            .addCase(getRewardAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getRewardAsync.fulfilled, (state, { payload }) => {
                state.reward = payload
                state.status = "idle";
            })
            .addCase(getRewardAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.reward = null
            })
            .addCase(getSupportDetailsAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSupportDetailsAsync.fulfilled, (state, { payload }) => {
                state.supportdetails = payload
                state.status = "idle";
            })
            .addCase(getSupportDetailsAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.supportdetails = null
            })
            .addCase(getWalletDataAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getWalletDataAsync.fulfilled, (state, { payload }) => {
                state.wallet = payload
                state.status = "idle";
            })
            .addCase(getWalletDataAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.wallet = null
            })
            .addCase(getSupportApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSupportApiAsync.fulfilled, (state, { payload }) => {
                state.support = payload
                state.status = "idle";
            })
            .addCase(getSupportApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.support = null
            })
            .addCase(getSubjectApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSubjectApiAsync.fulfilled, (state, { payload }) => {
                state.subject = payload
                state.status = "idle";
            })
            .addCase(getSubjectApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.subject = null
            })
           .addCase(supportApiUrlAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(supportApiUrlAsync.fulfilled, (state, { payload }) => {
                state.supportImage = payload
                state.status = "idle";
            })
            .addCase(supportApiUrlAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.supportImage = null
            })
            .addCase(supportCreateApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(supportCreateApiAsync.fulfilled, (state, { payload }) => {
                state.supportCreate = payload
                state.status = "idle";
            })
            .addCase(supportCreateApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.supportCreate = null
            })
            .addCase(getdeliveryRequestApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getdeliveryRequestApiAsync.fulfilled, (state, { payload }) => {
                state.deliveryRequest = payload
                state.status = "idle";
            })
            .addCase(getdeliveryRequestApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.deliveryRequest = null
            })
            .addCase(VerifyOrderDeliveryOtpApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(VerifyOrderDeliveryOtpApiAsync.fulfilled, (state, { payload }) => {
                state.orderVerifyOtp = payload
                state.status = "idle";
            })
            .addCase(VerifyOrderDeliveryOtpApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.orderVerifyOtp = null
            })
            .addCase(VerifyDeliveryOtpApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(VerifyDeliveryOtpApiAsync.fulfilled, (state, { payload }) => {
                state.deliveryVerifyOtp = payload
                state.status = "idle";
            })
            .addCase(VerifyDeliveryOtpApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.deliveryVerifyOtp = null
            })
            .addCase(VerifyDeliveryCancelOtpApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(VerifyDeliveryCancelOtpApiAsync.fulfilled, (state, { payload }) => {
                state.deliveryCancelledOtp = payload
                state.status = "idle";
            })
            .addCase(VerifyDeliveryCancelOtpApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.deliveryCancelledOtp = null
            })
            .addCase(getChecklistApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getChecklistApiAsync.fulfilled, (state, { payload }) => {
                state.checkList = payload
                state.status = "idle";
            })
            .addCase(getChecklistApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.checkList = null
            })
            .addCase(getRideHistoryApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getRideHistoryApiAsync.fulfilled, (state, { payload }) => {
                state.rideHistory = payload
                state.status = "idle";
            })
            .addCase(getRideHistoryApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.rideHistory = null
            })
            .addCase(getAppTrackerDataApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAppTrackerDataApiAsync.fulfilled, (state, { payload }) => {
                state.appTracker = payload
                state.status = "idle";
            })
            .addCase(getAppTrackerDataApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.appTracker = null
            })
            .addCase(getTripDataApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTripDataApiAsync.fulfilled, (state, { payload }) => {
                state.tripData = payload
                state.status = "idle";
            })
            .addCase(getTripDataApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.tripData = null
            })
            .addCase(changeDeliveryStatusApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(changeDeliveryStatusApiAsync.fulfilled, (state, { payload }) => {
                state.deliveryStatus = payload
                state.status = "idle";
            })
            .addCase(changeDeliveryStatusApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.deliveryStatus = null
            })
            .addCase(getTransectionHistoryAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getTransectionHistoryAsync.fulfilled, (state, { payload }) => {
                state.transectionHistory = payload
                state.status = "idle";
            })
            .addCase(getTransectionHistoryAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.transectionHistory = null
            })


    },
});

export const selectTrackData = (state) => state.dashboard.track;
export const selectMyDeliveryData = (state) => state.dashboard.mydelivery;
export const selectMyRewardsData = (state) => state.dashboard.reward;
export const selectSupportDetailsData = (state) => state.dashboard.supportdetails;
export const selectSupportData = (state) => state.dashboard.support;
export const selectWalletData = (state) => state.dashboard.wallet;
export const selectSubjectData = (state) => state.dashboard.subject;
export const selectUploadedUrlData = (state) => state.dashboard.supportImage;
export const selectChecklistData = (state) => state.dashboard.checkList;
export const selectRideHistoryData = (state) => state.dashboard.rideHistory;
export const selectAppTrackerData = (state) => state.dashboard.appTracker;
export const selectTripData = (state) => state.dashboard.tripData;
export const selectFuelBill = (state) => state.dashboard.fuelBill;
export const selectTransectionHistory = (state) => state.dashboard.transectionHistory
export default dashboardSlice.reducer;