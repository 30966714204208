import React, { useEffect, useState } from 'react'
import TrackImg from "../../../app/public/images/track-location.png";
import DtMap from "../../../app/public/images/dtmap.png";
import TimelineProf from "../../../app/public/images/timeline-prof.png";
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getMyDeliveryAsync, selectMyDeliveryData } from "./dashbaordSlice"
import {
    useJsApiLoader,
    GoogleMap,
    DirectionsRenderer,
} from '@react-google-maps/api'
import moment from 'moment-timezone';



const MyDeliveryHistory = () => {
    const location = useLocation()
    const [libraries] = useState(['places']);
    const [map, setMap] = useState(/** @type google.maps.Map */(null))
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
        libraries,
    })
    const [directionsResponse, setDirectionsResponse] = useState(null)
    const center = { lat: 30.6574932, lng: 76.727102 }
    const locationData = location?.state
    console.log(locationData, "location data")
    const deliveryData = useSelector(selectMyDeliveryData)
    const dispatch = useDispatch()
    async function calculateRoute() {
        if (locationData?.order_pick_address === '' || locationData?.order_delivery_address === '') {
            return
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: locationData?.order_pick_address,
            destination: locationData?.order_delivery_address,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
        })
        setDirectionsResponse(results)
        // setDistance(results.routes[0].legs[0].distance.text)
        // setDuration(results.routes[0].legs[0].duration.text)
    }

    useEffect(() => {
        dispatch(getMyDeliveryAsync())
        calculateRoute()
    }, []);
    return (
        <div className="main-wrapper">
            <div className="registration-upper reg-heading2">
                <h4><Link to='/dashboard/my-deliveries'><i className="fas fa-chevron-left"></i></Link> #{locationData?.driver_id}</h4>
                <div className="delivered-btn">
                    <span>{locationData?.status === "1" ? "Pending" : locationData?.status === "2" ? "Pending" : locationData?.status === "3" ? "Delivered" : ""}</span>
                </div>
            </div>

            <div className="delivery-dtlmain">
                {/* <div className="delivery-dtlmap">
                    <img src={DtMap} alt="" />
                    <span><img src={TrackImg} alt="" /></span>
                </div> */}
                <div className="MapOrders customMap">
                    <div className="MapOrders_inner customMap">
                        {
                            isLoaded ?
                                <GoogleMap
                                    center={center}
                                    zoom={15}
                                    mapContainerStyle={{ width: '100%', height: '100%' }}
                                    options={{
                                        zoomControl: false,
                                        streetViewControl: false,
                                        mapTypeControl: false,
                                        fullscreenControl: false,
                                    }}
                                    onLoad={map => setMap(map)}
                                >
                                    {directionsResponse && (
                                        <DirectionsRenderer directions={directionsResponse} />
                                    )}
                                </GoogleMap> :
                                <></>
                        }
                    </div>
                    {" "}
                </div>

                <div className="dlttrack-history">
                    <div className="dlttrack-box">
                        <div className="tracking-upperdt">
                            <h2>Tracking History</h2>
                        </div>

                        <div className="tracking-timelinedt">
                            <div className="verticle-timeline">
                                <ul>
                                    <li className={locationData?.customer_otp_status ? "check" : ""}>
                                        <h4>Verification</h4>
                                        <p>Waiting for OTP</p>
                                    </li>
                                    <li className={locationData?.status == 4 ? "check" : ""}>
                                        <h4 className="dark-thd">Delivery point</h4>
                                        <div className="timeline-prof">
                                            <img src={locationData?.user_info?.user_profiles?.profile_photo ? locationData?.user_info?.user_profiles?.profile_photo :TimelineProf} alt="" />
                                            <div className="timeline-profcnt">
                                                <h2> {`${locationData?.user_info?.user_profiles?.firstname} ${locationData?.user_info?.user_profiles?.lastname}`}</h2>
                                                <p>{locationData?.order_delivery_address}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className={locationData?.status >= 2 ? "check" : ""}>
                                        <h4>Pickup point</h4>
                                        <p>{locationData?.order_pick_address}</p>
                                        <p> {moment(locationData?.status_desc?.status_2_updated_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(locationData?.status_desc?.status_2_updated_at).format('hh:mm A')}</p>
                                    </li>
                                    <li className={locationData?.status >= 1 ? "check" : ""}>
                                        <h4>Accepted</h4>
                                        <p> {moment(locationData?.status_desc?.status_1_updated_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(locationData?.status_desc?.status_1_updated_at).format('hh:mm A')}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyDeliveryHistory