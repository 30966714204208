import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { getDeliveryData } from "../../../../actions/delivery/delivery/way-to-delivery";
import Phone from "../../../public/images/phone.svg";
import Profile from "../../../public/images/profile.png";
import Profile2 from "../../../public/images/profile-2.png";
import Camera from "../../../public/images/camera.png";
import Mic from "../../../public/images/mic.svg";
import SendCircle from "../../../public/images/send-circle.svg";
import moment from "moment-timezone";
import CommonLoader from "../../../components/shared/CommonLoader";
import { useDispatch, useSelector } from "react-redux";
import { getMessageListAsync, getMessageListHeadAsync, sendMessageToCustomerAsync } from "../../dashboard/dashbaordSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../auth/authSlice";

const Message = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [messageData, setMessageData] = useState([])
  const [msgLoader, setMsgLoader] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch()
  const auth = useSelector(selectLoginAuth)
  const currDate = new Date()
  console.log(location?.state, "location response")

  console.log(messageData, "message data")
  const handleMessageSubmit = () => {
    let params = {
      postData: {
        "recipient_id": `${location?.state?.user_info?.id}`,
        "content": message,
        "media_type": "text"
      },
      tokenData: auth?.payload?.token
    }
    setMsgLoader(true)
    dispatch((sendMessageToCustomerAsync(params)))
      .then(unwrapResult)
      .then((obj) => {

        dispatch((getMessageListHeadAsync(auth?.payload?.token)))
        .then(unwrapResult)
        .then((obj) => {
          if (obj?.data?.payload?.data?.length > 0) {
            let params = {
              postData: obj?.data?.payload?.data[0]?.id,
              tokenData: auth?.payload?.token
            }
            dispatch((getMessageListAsync(params)))
              .then(unwrapResult)
              .then((obj) => {
                setMessageData(obj?.data?.payload?.messages)
                setMsgLoader(false)
                setMessage("")
              }
              )
              .catch((obj) => {
                setMsgLoader(false)
              })
          }
        }
        )
        .catch((obj) => {
          setMsgLoader(false)
        })

      }
      )
      .catch((obj) => {
        setMsgLoader(false)
      })
  }
  // 5
  useEffect(() => {
    setLoading(true)
    dispatch((getMessageListHeadAsync(auth?.payload?.token)))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        if (obj?.data?.payload?.data?.length > 0) {
          let params = {
            postData: obj?.data?.payload?.data[0]?.id,
            tokenData: auth?.payload?.token
          }
          dispatch((getMessageListAsync(params)))
            .then(unwrapResult)
            .then((obj) => {
              setMessageData(obj?.data?.payload?.messages)
              setLoading(false)
            }
            )
            .catch((obj) => {
              setLoading(false)
            })
        }
      }
      )
      .catch((obj) => {
        setLoading(false)
      })

  }, [])

  return (
    <>
      <main className="main-wrapper" style={{ position: "absolute" }}>
        <div className="registration-upper reg-heading2 chatbox-header">
          <h4>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                history.goBack()
              }}
            >
              <i className="fas fa-chevron-left"></i>{" "}
            </Link>{" "}
            {location?.state ? `${location?.state?.user_info?.user_profiles?.firstname} ${location?.state?.user_info?.user_profiles?.lastname}` : ""}
          </h4>
          <div className="phone-icon">
            <Link to="#">
              <img src={Phone} alt="" />
            </Link>
          </div>
        </div>

        {
          loading ?
            <CommonLoader />
            :
            <>
              <div className="message-main">
                <div className="date-box">
                  <span>{moment(currDate).format('dddd | MMM DD, YYYY')}</span>
                </div>

                {
                  messageData?.map((val, index) => {
                    return (
                      <React.Fragment key={index}>
                        {
                          (val?.sender_id === auth?.payload?.id) ?
                            <div className="chat-rightprt">
                              <div className="chat-rightcnt">
                                <p>{val?.content}</p>
                                <span>{moment(val?.created_at).format('hh:mm A')}</span>
                              </div>
                              <img src={Profile} alt="" />
                            </div>
                            :
                            <div className="chat-leftprt">
                              <img src={Profile2} alt="" />
                              <div className="chat-leftcnt">
                                <p>
                                {val?.content}
                                </p>
                                <span>12:30 pm</span>
                              </div>
                            </div>
                        }
                      </React.Fragment>
                    )
                  })
                }

              </div>

              <div className="chat-bottom">
                <form action="#">
                  <div className="chat-btmitem">
                    <div className="chat-btminner">
                      <input type="text" placeholder="Type here..." value={message} onChange={(e) => setMessage(e.target.value)} />
                      <div className="chat-btmicon">
                        <button type="button">
                          <img src={Camera} alt="" />
                        </button>
                        <button type="button">
                          <img src={Mic} alt="" />
                        </button>
                      </div>
                    </div>
                    <div className="send-btn">
                      <button disabled={msgLoader === true} onClick={() => { handleMessageSubmit() }}>
                        {msgLoader === true ?
                          <span className="spinner-border spinner-border-sm"></span>
                          :
                          <img src={SendCircle} alt="" />
                        }
                        &nbsp;&nbsp;
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </>
        }
      </main>
    </>
  );
};

export default Message;