import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyBlack from "../../../../public/images/verify-black.svg";
import { useSelector } from "react-redux";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import CustomModal from "../../../../components/shared/CustomModal"
import { selectLoginAuth } from "../../../auth/authSlice";
import downArrow from "../../../../public/images/downArrow.svg"
import { getAddressFromCoordinates } from "../../../auth/register/AddressMethod";
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
} from '@react-google-maps/api'
const VerifyReturn = (props) => {
  const [loading] = useState(false)
  const [libraries] = useState(['places']);
  const auth = useSelector(selectLoginAuth)
  const [middlebody, setmiddlebody] = useState(false);
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries,
  })
  const center = { lat: 30.6574932, lng: 76.727102 }
  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
        .then(data => {
          calculateDriverRoute(data?.formatted_address)
        })
        .catch(err => console.log('error getting address', err));
    });
  }
  async function calculateDriverRoute(driverLoc) {
    if (driverLoc === '' || locationData?.order_pick_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: driverLoc,
      destination: locationData?.order_pick_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
  }
  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))

  const [otp, setOtp] = useState("");

  const [modalDetail, setModalDetail] = useState({
    show: false
  });
  const [modalKey, setModalKey] = useState(Math.random());

  const handleChange = (otp) => {
    setOtp(otp);
  }
  let data = {
    "postData": {
      "order_id": locationData?.order_id,
      "otp": "1234"
    },
    "tokenData": auth?.payload?.token
  }
  const handleVerify = async () => {
    if (!otp || otp.length < 4) {
      toast.error("Please enter your Otp");
      return false
    }
    setModalDetail({ show: true });
    setModalKey(Math.random());
  }

  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setModalKey(Math.random());
  }

  useEffect(() => {
    geocodeHandler()
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            {
              isLoaded ?
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={map => setMap(map)}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap> :
                <></>
            }
          </div>
          {" "}
        </div>

        <div className="pickup-pointbox">
          {
            !modalDetail.show ?
              <>
                <div className="deliver-verifybox" onClick={() => handleClick(true)}>
                  <h2>Verify your return</h2>
                  <p>
                    Ask for Return code from shop and <br /> verify it
                  </p>
                  <img className="dropArrow" src={downArrow} />
                </div>
                <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
                  <div className="verify-box d-flex justify-content-center">
                    <OTPInput className='input_digits_'
                      value={otp}
                      numInputs={4}
                      isInputNum={true}
                      data-cy="pin-field"
                      onChange={handleChange}
                    />
                  </div>

                  <div className="pickup-mbtn">
                    <button className="btn btn-primary continue" disabled={loading === true} onClick={handleVerify}>
                {loading === true && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                Verify</button>
                  </div>
                </div>
              </>
              : ""
          }
        </div>
      </div>

      <CustomModal
        key={modalKey}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        showCloseBtn={true}
        child={
          <div className="modal-body">
            <div className="modal-mainprt modal-boxblack">
              <div className="mdlverify-cnt">
                <h4>Verified</h4>
                <img src={VerifyBlack} alt="" />
                <p>You have completed delivery!</p>
                <h6>You received fees ${locationData?.amount}</h6>
              </div>
              <div className="popup-btn2">
                <ul>
                  <li>
                    <Link to="#" onClick={handleOnCloseModal}>
                      Cancel
                    </Link>
                  </li>
                  <li>
                    <Link to='/dashboard/wallet' className="active">
                      Wallet
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        }
        // size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default VerifyReturn;
