import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LogoIcon from "../../../../public/images/logo-icon_2.svg";
import Shop from "../../../../public/images/shop.png";
import { useSelector } from "react-redux";
import { selectPickupData } from "../../deliverySlice"
import downArrow from "../../../../public/images/downArrow.svg"
import { getAddressFromCoordinates } from "../../../auth/register/AddressMethod";
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
} from '@react-google-maps/api'

const WayToPickup = (props) => {
  const history = useHistory();
  const [libraries] = useState(['places']);
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const [middlebody, setmiddlebody] = useState(false);
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [driverDistance, setDriverDistance] = useState("")
  const [driverDuration, setDriverDuration] = useState("")
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries,
  })

  console.log(locationData,"locationdata")
  const pickupData = useSelector(selectPickupData)
  const center = { lat: 30.6574932, lng: 76.727102 }
  const geocodeHandler = async () => {
    await navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
        .then(data => {
          // setDriverLoc(data?.formatted_address);
          calculateDriverRoute(data?.formatted_address)
        })
        .catch(err => console.log('error getting address', err));
    });
  }
  async function calculateDriverRoute(driverLoc) {
    if (driverLoc === '' || locationData?.order_pick_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: driverLoc,
      destination: locationData?.order_pick_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
    setDriverDistance(results.routes[0].legs[0].distance.text)
    setDriverDuration(results.routes[0].legs[0].duration.text)
  }

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };

  const handlePickup = () => {
    history.push({ pathname: '/dashboard/express-delivery/pickup/verify-pickup' })
  }
  useEffect(() => {
    // calculateRoute()
    geocodeHandler()
  }, []);
  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            {
              isLoaded ?
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={map => setMap(map)}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap> : <></>
            }
          </div>
          {" "}
        </div>


        <div className="reqaccpt-exprs text-center">
          <span style={{ position: "relative" }}>
            Expree Delivery request accepted:{" "}
            <strong>{pickupData?.numberOfDeliveryRequestsAccepted}</strong>
          </span>
        </div>
        <div className="track-lcitem track-lctdl3">

        </div>

        <div className="pickup-pointbox">
          <div className="accept-mdlupper" onClick={() => handleClick(true)}>
            <h2>
              Way to<span>Pickup point</span>
            </h2>
            <img className="dropArrow" src={downArrow} />
          </div>

          <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
            <div className="accptmdl-uprtimeline">
              <ul>
                <li>
                  <div className="tmmin-cnt">
                    <p>
                      {driverDuration} {" "}
                      <span>{driverDistance} </span>
                    </p>
                  </div>
                  <div className="logot-icon2">
                    <img src={LogoIcon} alt="" />
                  </div>
                </li>
                <li>
                  <div className="shop-tml">
                    <img src={locationData?.seller_info?.user_profiles?.profile_photo ? locationData?.seller_info?.user_profiles?.profile_photo : Shop} alt="" />
                  </div>
                </li>
              </ul>
            </div>

            <div className="accept-mdltimline">
              <div className="verticle-timeline">
                <ul>
                  <li>
                    <h4>
                      Pickup point{" "}
                      <span>with in next {driverDuration} </span>
                    </h4>
                    <p>{locationData?.order_pick_address}</p>
                  </li>
                </ul>
              </div>
            </div>

            <div className="pickup-mbtn">
              <button className="btn btn-primary continue" onClick={() => handlePickup()}>
                Mark as pickup</button>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default WayToPickup;

