import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoginAuth } from '../containers/auth/authSlice';

const Authenticator = (props) => {
  const history = useHistory();
  const auth = useSelector(selectLoginAuth)
  const pathName = window.location.pathname;
  const signupPaths = ["/login", "/home", "/document-success", "/forgot-pin", "/verify-phone", "/set-new-pin",
    "/re-enter-new-pin", "/", "/verify-phone-on-register", "/register-email", "/verify-email", "/area-code-with-state",
    "/create-profile", "/register", "/registration-guideline", "/register2", "/registration-doc-pi", "/registration-doc-dd",
    "/registration-doc-vd", "/registration-doc-vrd", "/registration-doc-vid", "/register3", "/registration-agreement",
    "/register4", "/request-kyc", "/id-verification", "/business-id-verification", "/business-registration", "/kyb-verification"];
  const authenticateUser = (isAuthenticationRequired) => {
    if (auth?.payload?.token && auth?.payload?.user_profiles?.driver_steps === 5) {
      // If user are logedin then we can perform any action here
      if (signupPaths.includes(pathName)) {
        history.push('/express-pickup-request');
        // return false;
      }
    }
    else if (isAuthenticationRequired) {
      // If user are not login then redirect him to the login page
      history.push('/');
    }
  }

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);

  return (
    <>
      {props.children}
    </>
  )

}

export default Authenticator;