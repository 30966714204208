import React, { useEffect, useState } from "react";
import Logo from "../../public/images/logo.svg";
import Hamburger from "../../public/images/hambergur.svg";
import Bell from "../../public/images/bell.svg";
import { Link } from "react-router-dom";
import addNotification from 'react-push-notification';
import { getNotificationApiAsync } from "../../containers/dashboard/dashbaordSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginAuth } from "../../containers/auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// import '../../public/js/script';
// import profileImage from "../../public/images/user-profile.png";
const Header = (props) => {
  let userName = "User Name";
  const history = useHistory()
  const [notification, setNotification] = useState([])
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()
  // if (props.auth.isLoggedIn && props.auth.userProfile) {
  //   if (props.auth.userProfile.userName) {
  //     userName = props.auth.userProfile.userName;
  //   } else if (
  //     props.auth.userProfile.userType &&
  //     props.auth.userProfile.userType.userType === "Admin"
  //   ) {
  //     userName = "Admin";
  //   }
  // }
  // let nameFirstLetter = userName.charAt(0);
  function openNav() {
    document.getElementById("mySidenav").style.width = "308px";
  }


  const handleClick = (e, id) => {
    console.log(e,id,"event")
    history.push(`/notification`)
  }
  useEffect(() => {
    let getNotiParams = {
      postData: {
          page: 1,
          limit: 9
      },
      tokenData: auth?.payload?.token
  }
    // setLoading(true)
    dispatch(getNotificationApiAsync(getNotiParams))
      .then(unwrapResult)
      .then(async (obj) => {
        // setLoading(false)
        console.log(obj?.data?.payload?.data?.filter((val)=> val.is_read == false), "notify false data")
        obj?.data?.payload?.data?.filter((val)=> val.is_read == false)?.map((val)=> {return(
          addNotification({
            title: val?.notification?.title,
            subtitle: val?.notification?.description,
            message: val?.notification?.description,
            onClick: (e) => handleClick(e,val?.notification?.id),
            duration: 5000,
            theme: 'darkblue',
            native: true // when using native, your OS will handle theming.
        })
        )})
        setNotification(obj?.data?.payload?.data)
      }
      )
      .catch((obj) => {
        // setLoading(false)
      })
  }, [])

  return (
    <>
      <div className="header-area">
        <div className="header-left">
          <button type="button" onClick={openNav} style={{ cursor: "pointer" }}>
            <img src={Hamburger} alt="" />
          </button>
        </div>
        <div className="header-logo">
          <Link to="#">
            <img src={Logo} alt="logo" />
          </Link>
        </div>
        <div className="header-right">
          <Link to="/notification">
            <img src={Bell} alt="" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
