import axios from "axios";
import getProfileData1 from "../../db/profile.json";
// A mock function to mimic making an async request for data
export function getProfileData() {
    return getProfileData1
}


export function getProfilePhoto(data) {
    var formData = new FormData();
    formData.append('profile', data.profile);
    return new Promise((resolve, reject) =>
        axios.post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/profile`, formData, {
            headers: {
                'app-name': 'driver',
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}

export function getUserProfile(data) {
    const id = data.loginId;
    delete data.loginId;
    const token = data.token;
    delete data.token;
    return new Promise((resolve, reject) =>
        axios.get(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/${id}`, {
            headers: {
                'app-name': 'driver',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}
export function getUpdateProfile(data) {
    const id = data.loginId;
    delete data.loginId;
    const token = data.token;
    delete data.token;
    return new Promise((resolve, reject) =>
        axios.put(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/${id}`, data, {
            headers: {
                'app-name': 'driver',
                'Content-Type': 'application/json',
                'Authorization': token
            }
        })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error)
                console.error(error);
            })
    )
}