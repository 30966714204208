import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Location from "../../../../public/images/track-location-3.png";
import Circle from "../../../../public/images/circle-4.png";
import Order from "../../../../public/images/order-prof.png";
import Tell from "../../../../public/images/tell.svg";
import Chat from "../../../../public/images/chat.svg";
import Cross from "../../../../public/images/cross.svg";
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryDetailsAsync, selectDeliveryData } from "../../deliverySlice"
import CustomModal from "../../../../components/shared/CustomModal";

const WaitingOnDoor = (props) => {
  const history = useHistory();

  const dispatch = useDispatch()
  const deliveryData = useSelector(selectDeliveryData)
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: ""
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: ""
    });
    setKey(Math.random());
  }

  useEffect(() => {
    dispatch(getDeliveryDetailsAsync())
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
            width="573"
            height="700"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />{" "}
        </div>
        <div className="reqaccpt-exprs text-center hwd-uprbtn">
                  <span>
                    1 hour window delivery accepted: <strong>2</strong>
                  </span>
               </div>

        <div className="track-lcitem track-lctdl3">
          <img src={Location} alt="" />
        </div>

        <div className="pickup-pointbox pointbox-blue2">
          <div className="accept-mdlupper">
            <h2>
              Waiting on the door<span>AA2619-0822-RS0002</span>
            </h2>
            <img src={Circle} alt="" />
          </div>

          <div className="accept-mdltimline">
            <div className="order-prt">
              <img src={Order} alt="" />
              <div className="order-cnt">
                <h2>{deliveryData?.recipientName}</h2>
                <p>{deliveryData?.deliveryAddress}</p>
                <h2>Order#</h2>
                <p>{deliveryData?.orderNo}</p>
                <ul>
                  <li>
                    <Link to="#">
                      <img src={Tell} alt="" /> Call
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/dashboard/delivery/message", {
                          fromWaitingOnDoor: true,
                        });
                      }}
                    >
                      <img src={Chat} alt="" /> Message
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="pickup-mbtn">
            <Link to="#" onClick={() => { setModalDetail({ show: true }); setKey(Math.random()); }}>
              Cancel delivery
            </Link>
          </div>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={true}
        isRightSideModal={true}
        child={
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleOnCloseModal()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-mainprt modal-boxblack">
                <div className="mdlverify-cnt">
                  <h4>Canceled</h4>
                  <img src={Cross} alt="" />
                  <p>You have to return to shop</p>
                  <Link style={{ cursor: 'ponter' }} to='/dashboard/home-3'><h6>Return the order</h6></Link>
                </div>
              </div>
            </div>
          </div>
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default WaitingOnDoor;


// <div className="reqaccpt-exprs text-center hwd-uprbtn">
//           <span>
//             1 hour window delivery accepted: <strong>2</strong>
//           </span>
//         </div>