import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoIcon from "../../public/images/logo_icon.svg";
import LeftArrow from "../../public/images/left-arrow.svg";
import Dot from "../../public/images/dot.svg";
import Transfer from "../../public/images/transfer.svg";
import Money from "../../public/images/money.svg";
import DeliveryParcel from "../../public/images/delivery-parcel.svg";
import RightCircle from "../../public/images/bi_arrow-down-right-circle.svg";
import ParcelBox from "../../public/images/parcel-box.png";
import ArrowLeftUp from "../../public/images/arrow-leftup.png";
import { useSelector, useDispatch } from "react-redux";
import { getRewardAsync, getTransectionHistoryAsync, getWalletDataAsync, getdeliveryRequestApiAsync, selectMyRewardsData, selectTransectionHistory, selectWalletData } from "../../containers/dashboard/dashbaordSlice"
import CustomModal from "../../components/shared/CustomModal"
import InstantTransferModal from "./InstantTransferModal";
import NormalTransfer from "./NormalTransfer";
import PaymentMethodSelect from "./PaymentMethodSelect";
import AddCreditCard from "./AddCreditCard";
import PaymentMethod from "./PaymentMethod";
import PaginationSetting from "../../constants/PaginationSetting";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../auth/authSlice";
import Pagination from "@material-ui/lab/Pagination";
import { Box } from "@material-ui/core";
import moment from "moment-timezone";

const Wallet = (props) => {
  const [dotClickedFlag, setDotClickedFlag] = useState();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const walletData = useSelector(selectWalletData);
  const reward = useSelector(selectMyRewardsData);
  const [deliveredData, setDeliveredData] = useState("")
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    flag: ""
  });
  const auth = useSelector(selectLoginAuth)
  const [pagination, setPagination] = useState(PaginationSetting);
  const [page, setPage] = useState(1);
  const transectionHistoryData = useSelector(selectTransectionHistory);

  console.log(walletData, "handle record")

  const transactionsHistoryHandle = () => {
    let params = {
      postData: {
        page: page,
        limit: pagination.recordsPerPage,
        transaction_type: 'all'
      },
      tokenData: auth?.payload?.token
    }
    setLoading(true)
    dispatch(getTransectionHistoryAsync(params))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false)
        setPagination((prevState) => ({ ...prevState, totalPages: obj?.data?.payload?.total_pages, totalRecords: obj?.data?.payload?.total }));
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const openSmallModal = (e) => {
    e.preventDefault();
    setDotClickedFlag(!dotClickedFlag);
  };

  const smallModalStyleInactive = () => {
    return {
      width: "175px",
      background: "#FFFFFF",
      padding: "10px 16px",
      boxShadow: "4px 4px 8px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      position: "absolute",
      right: "-5px",
      top: "24px",
      display: "none",
    };
  };

  const smallModalStyleActive = () => {
    return {
      width: "175px",
      background: "#FFFFFF",
      padding: "10px 16px",
      boxShadow: "4px 4px 8px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      position: "absolute",
      right: "-5px",
      top: "24px",
      display: "block",
    };
  };

  const handleModalDetails = (data) => {
    if (data === 'normal_transfer' || data === 'instant_transfer' || data === "add_card" || data === "payment_method") {
      setModalDetail({ show: true, flag: data }); setKey(Math.random());
    }
  }

  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setKey(Math.random());
  }

  const getRewardAsyncFunc = () => {
    setLoading(true)
    dispatch(getRewardAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  const getDeliveredCountFunc = () => {
    setLoading(true)
    let deliveredParams = {
      postData: {
        driver_id: auth?.payload?.uuid,
        status: "3"
      },
      tokenData: auth?.payload?.token
    }
    dispatch(getdeliveryRequestApiAsync(deliveredParams))
      .then(unwrapResult)
      .then((obj) => {
        setLoading(false)
        setDeliveredData(obj?.data?.payload?.data)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  const walletBalanceHandle = () => {
    setLoading(true)
    dispatch(getWalletDataAsync(auth?.payload?.token))
      .then(unwrapResult)
      .then(async (obj) => {
        setLoading(false)
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    transactionsHistoryHandle()
  }, [page])

  useEffect(() => {
    getRewardAsyncFunc()
    getDeliveredCountFunc()
    walletBalanceHandle()
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          JBR Wallet
        </h4>
      </div>

      <div className="wallet-main">
        <div className="ballance-box">
          <div className="ballance-upper">
            <img src={LogoIcon} alt="" />
            <div className="balance-cnt">
              <h4>
                Available Balance{" "}
                <Link to="#">
                  <img
                    src={Dot}
                    alt=""
                    onClick={(e) => {
                      openSmallModal(e);
                    }}
                  />
                </Link>
              </h4>
              <p>JBR {walletData?.data?.payload?.sila_balance}</p>

              <div
                className={dotClickedFlag ? "balance-dropdown" : ""}
                style={
                  dotClickedFlag
                    ? smallModalStyleActive()
                    : smallModalStyleInactive()
                }
              >
                <Link
                  to="#"
                  onClick={() => { setModalDetail({ show: true, flag: 'instant_transfer', }); setKey(Math.random()); }}
                >
                  <img src={Transfer} alt="" /> Transfer
                </Link>{" "}
                <br />
                <Link
                  to="#"
                  onClick={() => { setModalDetail({ show: true, flag: 'payment_selected', }); setKey(Math.random()); }}
                >
                  <img src={Money} alt="" /> Payment method
                </Link>
              </div>

            </div>
          </div>

          <div className="balance-itminr">
            <h4>
              <img src={DeliveryParcel} alt="" /> Earn
            </h4>
            <h4>
              <img src={RightCircle} alt="" /> Withdrawal
            </h4>
          </div>
        </div>

        <div className="delivery-history">
          <h2>Delivery history</h2>
          <div className="delivery-histitem">
            <div className="delivery-histbox">
              <h4>
                <i className="fas fa-trophy"></i> {reward?.data?.payload?.availabe_points}
              </h4>
              <p>{reward?.data?.payload?.member_ship}</p>
            </div>
            <div className="delivery-histbox dlbg2">
              <h4>{deliveredData ? deliveredData.length : 0}</h4>
              <p>Delivered</p>
            </div>
            <div className="delivery-histbox dlbg3">
              <h4>0</h4>
              <p>Returned</p>
            </div>
          </div>
        </div>

        <div className="trans-history">
          <h2>Transection history</h2>
          <ul>
            {transectionHistoryData?.data?.payload?.data?.length > 0 ? (
              <>
                {transectionHistoryData?.data?.payload?.data?.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li>
                        <div className="trans-histleft">
                          <img
                            src={
                              value?.payment_type === "issue_sila"
                                ? ParcelBox
                                : ArrowLeftUp
                            }
                            alt=""
                          />
                          <div className="trans-histcnt">
                            <h4>{value?.payment_type}</h4>
                            <p>{moment(value?.createdAt).format('YYYY-MM-DD')}</p>
                          </div>
                        </div>
                        <div className="trans-histright">
                          <p>JBR {value?.amount}</p>
                        </div>
                      </li>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>No Transaction Found</>
            )}
          </ul>
        </div>
      </div>
      {pagination.totalRecords > pagination.recordsPerPage &&
        <Box py={3} display="flex" justifyContent="center">
          <Pagination
            key={page}
            count={pagination.totalPages || 0}
            color="secondary"
            variant="outlined"
            onChange={(e, value) => setPage(value)}
            defaultPage={page}
          />
        </Box>
      }

      <CustomModal
        key={key}
        show={modalDetail.show}
        size="lg"
        // backdrop="static"
        title={modalDetail.title}
        fullwidth_payment={true}
        child={
          modalDetail.flag === 'instant_transfer' ?
            <InstantTransferModal getTransaction={() => transactionsHistoryHandle()} getWallet={() => walletBalanceHandle()} walletData={walletData?.data?.payload?.sila_balance} close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
            : (modalDetail.flag === "normal_transfer") ? <NormalTransfer getTransaction={() => transactionsHistoryHandle()} getWallet={() => walletBalanceHandle()} walletData={walletData?.data?.payload?.sila_balance} close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
              : (modalDetail.flag === "payment_selected") ? <PaymentMethodSelect close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
                : (modalDetail.flag === "add_card") ? <AddCreditCard close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
                  : (modalDetail.flag === "payment_method") ? <PaymentMethod close={() => handleOnCloseModal()} /> : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Wallet;