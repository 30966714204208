// import axios from "axios";
import axios from 'axios';
import { toast } from "react-toastify";
import { WALLET_API_URL } from "./../../config"
const queryString = require('query-string');
export function registerApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliduserdata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invaliduserdata", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        // console.error(error?.response, "response msg");
      })
  )
}
export function registerSendOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        // console.error(error);
      })
  )
}
export function verifyOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/verify-phone/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function loginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/login/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function emailSendApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/send-otp`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        localStorage.removeItem("invaliddata")
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function emailVerifiedApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/verify-otp/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        localStorage.removeItem("invalidotp")
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invalidotp", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getCountryApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/countries`, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getDistrictApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/districts?country_id=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getStateApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/districts?country_id=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getCityApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/cities?state_id=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getVehicleMakeApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/vehicles`, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getVehicleModelYearApi(data, token) {
  let query = queryString.stringify(data)
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/vehicles` + "?" + query, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getUploadDocumentUrlApi(data) {
  var formData = new FormData();
  formData.append('document', data.document);
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/verification-document`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function driverDocumentStepApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/vehical-document`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver',
          'Authorization': token
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function changePinApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/change-pin/`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        localStorage.removeItem("/invalidotp")
        // toast.success(response?.data?.msg)
        localStorage.removeItem("setpin")
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invalidotp", error?.response?.data?.msg)
        localStorage.setItem("setpin", error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function roleApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_roles`, data, {
        headers: {
          'Content-Type': 'application/json',
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function createWalletApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload[0] ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
        console.error(error?.response, "response error");
      })
  )
}
export function requestKyc(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function checkKyc(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getDocumentType(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/document-types`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function uploadKycDocument(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets/upload-documents`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error?.response, "response error");
      })
  )
}
export function getBusinessTypes(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/business-types`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getNaicsCategories(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/naics-categories`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function businessRegistrationApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets/business/register`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.payload[0] ? error?.response?.data?.payload[0] : error?.response?.data?.msg)
      })
  )
}
export function businessKycApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/business/request-kyc`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getWalletProfile(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function uploadKybDocument(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets/business/upload-documents`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error?.response, "response error");
      })
  )
}
export function generatePlaidTokenApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/plaid/token`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getBankAccountApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${WALLET_API_URL}api/v1/wallets/bank-accounts`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function linkBankAccount(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${WALLET_API_URL}api/v1/wallets/link-bank-account`, data, {
        headers: {
          'Authorization': token,
          'app-name': 'driver'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error?.response, "response error");
      })
  )
}
export function getUserProfile(data) {
  const id = data.loginId;
  delete data.loginId;
  const token = data.token;
  delete data.token;
  return new Promise((resolve, reject) =>
    axios.get(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
        'app-name': 'driver'
      }
    })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}