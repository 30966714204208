if (process.env.NODE_ENV === "production") {

  module.exports = {
    WALLET_API_URL: "https://apiwallet.jobr.com/",
   };
 
 } else {
 
  module.exports = {
    WALLET_API_URL: "https://apiwallet.jobr.com/",
   };
 }