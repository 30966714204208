import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../../public/images/left-arrow.svg";
import { createWalletAsync, driverDocumentStepAsync, selectLoginAuth, selectUserProfile, userProfileAsync } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const RegistrationDocPi = () => {
  const history = useHistory();
  const [dob, setDob] = useState("")
  const [ssn, setSsn] = useState("")
  const [gender, setGender] = useState("male")
  const [apt, setApt] = useState("")
  const [zipcode, setZipCode] = useState("")
  const [address, setAddress] = useState("")
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [piLoading, setPiLoading] = useState(false)
  const [countryCode, setCountryCode] = useState("")
  const [countryValue, setCountryValue] = useState("");
  const [stateCode, setStateCode] = useState("")
  const [stateValue, setStateValue] = useState("")
  const [cityValue, setCityValue] = useState("")
  const [email, setEmail] = useState("")
  const [type, setType] = useState("individual")
  const selectProfile = useSelector(selectUserProfile);

  console.log(selectProfile,"select profile")

  const autoCompleteHandleChange = (address) => {
    if (address === "") {
      setCityValue("")
      setStateValue("")
      setCountryValue("")
      setZipCode("")
      setApt("")
    }
    setAddress(address);
  };

  const autoCompleteHandleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        fillAddressDetails(results);
      })
      .catch((error) => { });
  };

  const fillAddressDetails = (results) => {
    setAddress(results[0].formatted_address);

    for (let j = 0; j < results[0].address_components.length; j++) {
      if (results[0].address_components[j].types[0] == "postal_code") {
        setZipCode(results[0].address_components[j].short_name);
      } else if (results[0].address_components[j].types[0] == "locality") {
        setCityValue(results[0].address_components[j].long_name);
      } else if (
        results[0].address_components[j].types[0] ==
        "administrative_area_level_1" ||
        results[0].address_components[j].types[0] === 'administrative_area_level_3' ||
        results[0].address_components[j].types[0] === 'locality'
      ) {
        setStateValue(results[0].address_components[j].long_name);
        setStateCode(results[0].address_components[j]?.short_name)
      } else if (results[0].address_components[j].types[0] == "country") {
        setCountryValue(results[0].address_components[j].long_name);
        setCountryCode(results[0].address_components[j]?.short_name);
      }
      else if(results[0].address_components[j].types[0] == 'street_number'){
        setApt(results[0].address_components[j].long_name);
      }
    }
  };
  const auth = useSelector(selectLoginAuth)

  console.log(auth, "selected auth data")

  const gotoRegister2Page = (e) => {
    e.preventDefault();
    history.push("/register2");
  };
  let data = {}
  if (apt) {
    data = {
      "postData": {
        "driver_steps": "1",
        "dob": dob,
        "ssn_number": ssn,
        "gender": gender,
        "current_address": {
          "street_address": address.slice(0,40),
          "apt": apt,
          "state": stateValue,
          "city": cityValue,
          "zipcode": zipcode,
          "country": countryValue,
          "address_type": "current"
        }
      },
      "tokenData": auth?.payload?.token
    }
  }
  else {
    data = {
      "postData": {
        "driver_steps": "1",
        "dob": dob,
        "ssn_number": ssn,
        "gender": gender,
        "current_address": {
          "street_address": address.slice(0,40),
          "state": stateValue,
          "city": cityValue,
          "zipcode": zipcode,
          "country": countryValue,
          "address_type": "current"
        }
      },
      "tokenData": auth?.payload?.token
    }


  }
  const gotoRegistrationDocDdPage = async (e) => {
    e.preventDefault();
    if (!dob) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your DOB");
      }
      return;
    }
    if (!ssn) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your ssn");
      }
      return;
    }
    if (ssn.length < 9) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("ssn number should be of 9 digits");
      }
      return;
    }
    if (!email) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your email");
      }
      return;
    }
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    var resultemail = pattern.test(email);

    if (resultemail === false) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid email");
      }
      return;
    }
    if (!address.length > 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your street address");
      }
      return;
    }
    if (!zipcode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your zipcode");
      }
      return;
    }
    if (!countryValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter country")
      }
      return
    }
    if (!stateValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter state")
      }
      return
    }
    if (!cityValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter City")
      }
      return
    }
    setPiLoading(true)

    let walletData = {
      postData: {
        "type": type,
        "first_name": selectProfile?.payload?.user_profiles?.firstname,
        "last_name": selectProfile?.payload?.user_profiles?.lastname,
        "address_type": "current",
        "address": address,
        "city": cityValue,
        "state": stateCode,
        "zip": zipcode,
        "country": countryCode,
        "phone": selectProfile?.payload?.user_profiles?.phone_no,
        "email": selectProfile?.payload?.email,
        "dob": moment(dob).format('YYYY-MM-DD'),
        "ssn": ssn
      },
      tokenData: auth?.payload?.token
    }
    await dispatch(driverDocumentStepAsync(data))
      .then(unwrapResult)
      .then(async (obj) => {
        await dispatch(createWalletAsync(walletData))
          .then(unwrapResult)
          .then((obj) => {
            setPiLoading(false)
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.success(obj?.msg)
            }
            history.push("/request-kyc");
          }
          )
          .catch((obj) => {
            setPiLoading(false)
          })
      }
      )
      .catch((obj) => {
        setPiLoading(false)
      })
  }
  useEffect(() => {
    let params = {
        loginId: auth?.payload?.id,
        token: auth?.payload?.token
    }
    dispatch(userProfileAsync(params))
}, [])
  return (
    <>
      <main className="main-wrapper">
        <div className="registration-upper reg-heading2">
          <h4>
            <Link to="#" onClick={(e) => gotoRegister2Page(e)}>
              <img src={LeftArrow} alt="" />
            </Link>{" "}
            Documents collections
          </h4>
        </div>

        <div className="personal-infomain">
          <h2>Personal Information</h2>
          <label htmlFor="p1">Date of birthday (month / day / year)</label>
          {/* <input
              className="date"
              type="date"
              id="datePickerId"
              placeholder="mm / dd / yyyy"
              value={dob}
              // max={date}
              onChange={e => setDob(e.target.value)}
            /> */}
          <form>
            <DatePicker
              selected={dob}
              onChange={(date) => setDob(date)}
              dateFormat="MM/dd/yyyy"
              maxDate={moment().subtract(21, 'years')._d}
              placeholderText="MM/DD/YYYY"
              className="date"
              autoComplete="off"
            />
          </form>
          <input type="password" placeholder="SSN" value={ssn} onChange={e => setSsn(e.target.value)} maxLength={9} />
          <input type="email" placeholder="Please enter your email" value={email} onChange={e => setEmail(e.target.value)} />

          <div className="gender-prt">
            <h4>Type</h4>
            <div className="">
              <label className='radioInline'>
                <input type="radio" name="type" value="30" checked={type === 'individual'}
                  onChange={() => setType('individual')} />Individual
              </label>
              <label className='radioInline'>
                <input type="radio" name="type" value="30" checked={type === 'business'}
                  onChange={() => setType('business')} />Business
              </label>
            </div>
          </div>

          <div className="gender-prt">
            <h4>Gender</h4>
            <div className="">
              <label className='radioInline'>
                <input type="radio" name="age" value="30" checked={gender === 'male'}
                  onChange={() => setGender('male')} />Male
              </label>
              <label className='radioInline'>
                <input type="radio" name="age" value="30" checked={gender === 'female'}
                  onChange={() => setGender('female')} />Female
              </label>
            </div>
          </div>
          <label htmlFor="p2">Current Addreess</label>
          <PlacesAutocomplete
            value={address}
            onChange={autoCompleteHandleChange}
            onSelect={autoCompleteHandleSelect}
            searchOptions={{
              componentRestrictions: {
                country: ['us'],
              }
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Street Address',
                    className: 'location-search-input',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion,index) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                      key={index}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          <input type="text" placeholder="Apt. " value={apt} onChange={e => setApt(e.target.value)} />
          <input type="text" placeholder="Please enter city " value={cityValue} onChange={e => setCityValue(e.target.value)} />
          <input type="text" placeholder="Please enter state " value={stateValue} onChange={e => setStateValue(e.target.value)} />
          <input type="text" placeholder="Zip Code" value={zipcode} onChange={e => setZipCode(e.target.value)} maxLength={6}/>
          <input type="text" placeholder="Please enter country " value={countryValue} onChange={e => setCountryValue(e.target.value)} />

          <button type="submit" className="document_button" disabled={piLoading === true} onClick={(e) => gotoRegistrationDocDdPage(e)}>
            {piLoading === true && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            Next (Step 1/5)
          </button>
        </div>
      </main>
    </>
  );
};

export default RegistrationDocPi;