
import { combineReducers } from "redux";
import sharedReducer from "../../src/app/components/shared/sharedSlice"
import dashboardReducer from "../../src/app/containers/dashboard/dashbaordSlice"
import authReducer from "../../src/app/containers/auth/authSlice"
import deliveryReducer from "../../src/app/containers/delivery-screens/deliverySlice"
import editProfilePhotoReducer from '../../src/app/components/shared/sharedSlice'

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  auth: authReducer,
  shared: sharedReducer,
  dashboard: dashboardReducer,
  editProfilePhoto: editProfilePhotoReducer, 
  delivery: deliveryReducer
});

export default rootReducer;