import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LockGreen from "../../../public/images/lock-green.png";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { registerOtpSendAsync, registerOtpVerifyAsync, selectOtpId, selectPhoneVerify } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const VerifyPhoneOnRegister = (props) => {
  const history = useHistory();
  const otpId = useSelector(selectOtpId);
  const [verifyPhoneLoading, setVerifyPhoneLoading] = useState(false);
  const isVerified = useSelector(selectPhoneVerify)
  const [otp, setOtp] = useState("");
  if (otpId?.payload?.is_phone_exits === true) {
    history.push('/login')
  }
  const handleChange = (otp) => {
    setOtp(otp);
  }
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const handleOnSubmit = async () => {
    let data = {
      "id": otpId?.payload?.id,
      otp: Number(otp),
      "role_id": 3
    }
    if (otp.length < 5) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter a valid OTP");
      }
      return;
    }
    if (!otp) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter OTP");
      }
      return;
    }
    setVerifyPhoneLoading(true)
    await dispatch(registerOtpVerifyAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        setVerifyPhoneLoading(false)
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/register-email')
      }
      )
      .catch((obj) => {
        setVerifyPhoneLoading(false)
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  }
  let data1 = JSON.parse(localStorage.getItem("phonedetails"))
  let value = {
    "phone_code": data1?.phone_code,
    "phone_no": data1?.phone_no
  }
  const handleResend = () => {
    dispatch(registerOtpSendAsync(value))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
      }
      )
      .catch((obj) => {
      })
  }

  return (
    <>
      <main className="main-wrapper">
        <div className="lock-part2">
          <img src={LockGreen} alt="" />
        </div>

        <div className="verify-part">
          <h2>
            Verify your <strong>phone number</strong>
          </h2>
          <p>Enter OTP code here</p>
          <div className="verify-box d-flex justify-content-center">
            <OTPInput className='input_digits_'
              numInputs={5}
              isInputNum={true}
              data-cy="pin-field"
              value={otp}
              onChange={handleChange}
            // onComplete={(code) => onComplete(code)}
            />
          </div>
        </div>

        <div className="bottom-part mt-3">
          <p>
            Didn’t receive Code? <Link to="#" onClick={() => handleResend()}>Resend</Link>
          </p>
          <button className="btn btn-primary continue" disabled={verifyPhoneLoading === true} onClick={handleOnSubmit} >

            {verifyPhoneLoading === true && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            Continue
          </button>
        </div>
      </main>
    </>
  );
};

export default VerifyPhoneOnRegister;