import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useSelector, useDispatch } from "react-redux";
import { VerifyOrderDeliveryOtpApiAsync, changeDeliveryStatusApiAsync } from "../../../dashboard/dashbaordSlice";
import { selectLoginAuth } from "../../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import downArrow from "../../../../public/images/downArrow.svg"
import { getAddressFromCoordinates } from "../../../auth/register/AddressMethod";
import {
  useJsApiLoader,
  GoogleMap,
  DirectionsRenderer,
} from '@react-google-maps/api'

const VerifyPickup = (props) => {
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [libraries] = useState(['places']);
  const [loading, setLoading]= useState(false)
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const [middlebody, setmiddlebody] = useState(false);

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries,
  })

  const center = { lat: 30.6574932, lng: 76.727102 }
  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
        .then(data => {
          calculateDriverRoute(data?.formatted_address)
        })
        .catch(err => console.log('error getting address', err));
    });
  }

  async function calculateDriverRoute(driverLoc) {
    if (driverLoc === '' || locationData?.order_pick_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: driverLoc,
      destination: locationData?.order_pick_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    setDirectionsResponse(results)
  }

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };
  const handleChange = (otp) => {
    setOtp(otp);
  }
  const handleVerify = async () => {
    if (!otp || otp.length < 4) {
      toast.error("Please enter your Otp");
      return false
    }
    let data = {
      "postData": {
        "order_id": `${locationData?.order_id}`,
        "otp": otp,
        "flag": "seller"
      },
      "tokenData": auth?.payload?.token
    }
    setLoading(true)
    await dispatch(VerifyOrderDeliveryOtpApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.data?.msg)
        }
        let data1 = {
          "postData": {
            "order_id": `${locationData?.order_id}`,
            "status": "3"
          },
          "tokenData": auth?.payload?.token
        }
        dispatch(changeDeliveryStatusApiAsync(data1))
          .then(unwrapResult)
          .then((obj) => {
            setLoading(false)
            if (!toast.isActive(toastId.current)) {
              toastId.current = toast.success(obj?.data?.msg)
            }
            history.push({ pathname: '/dashboard/express-delivery/delivery/way-to-delivery', state: JSON.stringify(locationData) })
          }
          )
          .catch((obj) => {
            setLoading(false)
            // if (!toast.isActive(toastId.current)) {
            //   toastId.current = toast.error(obj?.message)
            // }
          })
      }
      )
      .catch((obj) => {
        setLoading(false)
      })
  }
  useEffect(() => {
    geocodeHandler()
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            {
              isLoaded ?
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: '100%', height: '100%' }}
                  options={{
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                  }}
                  onLoad={map => setMap(map)}
                >
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap> : <></>
            }
          </div>
          {" "}
        </div>

        <div className="pickup-pointbox">
          <div className="deliver-verifybox" onClick={() => handleClick(true)}>
            <h2>Verify Pickup</h2>
            <img className="dropArrow" src={downArrow} />
            <p>
              Ask for Order code from shop and <br /> verify it
            </p>
          </div>
          <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
            <div className="verify-box d-flex justify-content-center">
              <OTPInput className='input_digits_'
                value={otp}
                numInputs={4}
                isInputNum={true}
                data-cy="pin-field"
                onChange={handleChange}
              // onChange ={() => {}} 
              // onComplete={(code) => onComplete(code)}
              />
            </div>
            <div className="pickup-mbtn">
              <button className="btn btn-primary continue" disabled={loading === true} onClick={handleVerify}>
                {loading === true && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
                Verify</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPickup