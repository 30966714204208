import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LeftArrow from "../../public/images/left-arrow.svg";
import { selectLoginAuth } from "../auth/authSlice";
import {getUserSettingApiAsync,updateUserSettingApiAsync } from "./dashbaordSlice"

const Settings = (props) => {
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [notificationSetting, setNotificationSetting] = useState(false)
  const [emailNotificationSetting, setEmailNotificationSetting] = useState(false)
  const [pushNotificationSetting, setPushNotificationSetting] = useState(false)
  const [chatNotificationSetting, setChatNotificationSetting] = useState(false)

  const updateSetting = (flag, state) => {
    let params = {
        tokenData: auth?.payload?.token,
        postData: {
            "app_name": "driver",
            [flag]: state
        }
    }
    setLoader(true)
    dispatch(updateUserSettingApiAsync (params))
        .then(unwrapResult)
        .then(async (obj) => {
            let params = {
                tokenData: auth?.payload?.token,
                postData: {     
                    "app_name": "driver"
                }
            }
            dispatch(getUserSettingApiAsync(params))
                .then(unwrapResult)
                .then(async (obj) => {
                    setNotificationSetting(obj?.data?.payload?.notification_status)
                    setEmailNotificationSetting(obj?.data?.payload?.email_notification_status)
                    setChatNotificationSetting(obj?.data?.payload?.chat_notification_status)
                    setPushNotificationSetting(obj?.data?.payload?.push_notification_status)
                    setLoader(false)
                }
                )
                .catch((obj) => {
                    setLoader(false)
                })
        }
        )
        .catch((obj) => {
            setLoader(false)
        })
}

const handleCheckboxChange = (flag) => {
  if (flag === "notification") {
      setNotificationSetting(prev => !prev)
      updateSetting("notification_status", !notificationSetting)
  }
  if (flag === "email") {
      setEmailNotificationSetting(prev => !prev)
      updateSetting("email_notification_status", !emailNotificationSetting)
  }
  if (flag === "push") {
      setPushNotificationSetting(prev => !prev)
      updateSetting("push_notification_status", !pushNotificationSetting)
  }
  if (flag === "chat") {
      setChatNotificationSetting(prev => !prev)
      updateSetting("chat_notification_status", !chatNotificationSetting)
  }
}

  useEffect(() => {
    let params = {
      postData: {
        app_name: "driver",
      },
      tokenData: auth?.payload?.token
    }
    setLoader(true)
    dispatch(getUserSettingApiAsync(params))
      .then(unwrapResult)
      .then(async (obj) => {
        setNotificationSetting(obj?.data?.payload?.notification_status)
        setEmailNotificationSetting(obj?.data?.payload?.email_notification_status)
        setChatNotificationSetting(obj?.data?.payload?.chat_notification_status)
        setPushNotificationSetting(obj?.data?.payload?.push_notification_status)
        setLoader(false)
      }
      )
      .catch((obj) => {
        setLoader(false)
      })

  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          Settings
        </h4>
      </div>

      <div className="settings-main">
        <div className="settings-box">
          <h4>Notifications</h4>
          <ul>
            <li>
              Notification
              <label className="switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={notificationSetting} 
                onChange={() => handleCheckboxChange("notification")}  />
                <span className="slider round" htmlFor="customSwitch1"></span>
              </label>
            </li>
            <li>
            Email Notification
              <label className="switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch2" checked={emailNotificationSetting} 
                onChange={() => handleCheckboxChange("email")}/>
                <span className="slider round" htmlFor="customSwitch2"></span>
              </label>
            </li>
            <li>
            Push Notification
              <label className="switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch3" checked={pushNotificationSetting} 
                onChange={() => handleCheckboxChange("push")}/>
                <span className="slider round" htmlFor="customSwitch3"></span>
              </label>
            </li>
            <li>
            Chat Notification
              <label className="switch">
                <input type="checkbox" className="custom-control-input" id="customSwitch4" checked={chatNotificationSetting}
                 onChange={() => handleCheckboxChange("chat")} />
                <span className="slider round" htmlFor="customSwitch4"></span>
              </label>
            </li>
          </ul>
        </div>

      { /*  <div className="settings-box">
          <h4>Distance</h4>
          <ul>
            <li>
              Show in KM
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        </div> */
}
    { /*    <div className="settings-box">
          <h4>Accounts</h4>
          <ul>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default Settings;

