import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    registerSendOtp,
    verifyOtp,
    loginApi,
    emailSendApi,
    emailVerifiedApi,
    getCountryApi,
    getDistrictApi,
    getCityApi,
    registerApi,
    getVehicleMakeApi,
    getUploadDocumentUrlApi,
    driverDocumentStepApi,
    changePinApi,
    getStateApi,
    roleApi,
    createWalletApi,
    requestKyc,
    checkKyc,
    getDocumentType,
    uploadKycDocument,
    getBusinessTypes,
    getNaicsCategories,
    businessRegistrationApi,
    businessKycApi,
    getWalletProfile,
    uploadKybDocument,
    generatePlaidTokenApi,
    getBankAccountApi,
    linkBankAccount,
    getUserProfile,
    getVehicleModelYearApi
} from "./authApi";
import { toast } from "react-toastify";

const initialState = {
    user: null,
    otp: null,
    verify: null,
    emailOtp: null,
    emailVerified: null,
    country: [],
    district: [],
    city: [],
    state: [],
    vehicleMake: [],
    vehcileModel: [],
    vehicleYear: [],
    vehicleVins: [],
    driverDocument: null,
    register: null,
    uploadUrl1: null,
    uploadUrl2: null,
    uploadUrl3: null,
    changepin: null,
    role: null,
    walletProfile: null,
    requestKyc: null,
    checkKyc: null,
    securityPin: false,
    documentType: null,
    kycDocument: null,
    businessType: null,
    naicsCategory: null,
    businessRegister: null,
    businessKyc: null,
    kybDocument: null,
    generateToken: null,
    linkBank: null,
    bankDetail: null,
    userProfile: null,
    status: "idle",
    status1: {
        DocumentStatus: "idle",
        otpStatus: "idle",
        verifyStatus: "idle",
        loginStatus: "idle",
        emailSendStatus: "idle",
        VerifyEmailStatus: "idle",
        cityStatus: "idle",
        countryStatus: "idle",
        registerStatus: "idle",
        changePinStatus: "idle"
    }
};

export const registerOtpSendAsync = createAsyncThunk("auth/registerotp", async (data) => {
    const response = await registerSendOtp(data);
    return response.data;
});
export const registerOtpVerifyAsync = createAsyncThunk("auth/verifyotp", async (data) => {
    const response = await verifyOtp(data);
    return response.data;
});
export const loginAsync = createAsyncThunk("auth/login", async (data) => {
    const response = await loginApi(data);
    return response.data;
});
export const emailSendAsync = createAsyncThunk("auth/emailotp", async (data) => {
    const response = await emailSendApi(data);
    return response.data;
});
export const emailVerifiedAsync = createAsyncThunk("auth/verifiedotp", async (data) => {
    const response = await emailVerifiedApi(data);
    return response.data;
});
export const countryAsync = createAsyncThunk("auth/country", async () => {
    const response = await getCountryApi();
    return response.data;
});
export const districtAsync = createAsyncThunk("auth/district", async (data) => {
    const response = await getDistrictApi(data);
    return response.data;
});
export const stateAsync = createAsyncThunk("auth/state", async (data) => {
    const response = await getStateApi(data);
    return response.data;
});
export const cityAsync = createAsyncThunk("auth/city", async (data) => {
    const response = await getCityApi(data);
    return response.data;
});
export const registerAsync = createAsyncThunk("auth/register", async (data) => {
    const response = await registerApi(data);
    return response.data;
});
export const vehicleMakeAsync = createAsyncThunk("auth/make", async (data) => {
    const response = await getVehicleMakeApi(data);
    return response.data;
});
export const vehicleModelYearAsync = createAsyncThunk("auth/model", async (data) => {
    const response = await getVehicleModelYearApi(data.postData, data.tokenData);
    return response.data;
});
export const getUploadUrlAsync1 = createAsyncThunk("auth/url", async (data) => {
    const response = await getUploadDocumentUrlApi(data);
    return response.data;
});
export const getUploadUrlAsync2 = createAsyncThunk("auth/url1", async (data) => {
    const response = await getUploadDocumentUrlApi(data);
    return response.data;
});
export const getUploadUrlAsync3 = createAsyncThunk("auth/url2", async (data) => {
    const response = await getUploadDocumentUrlApi(data);
    return response.data;
});
export const driverDocumentStepAsync = createAsyncThunk("auth/driverdocument", async (data) => {
    const response = await driverDocumentStepApi(data.postData, data.tokenData);
    return response.data;
});
export const changePinAsync = createAsyncThunk("auth/changepin", async (data) => {
    const response = await changePinApi(data);
    return response.data;
});
export const roleAsync = createAsyncThunk("auth/roleapi", async (data) => {
    const response = await roleApi(data);
    return response.data;
});
export const createWalletAsync = createAsyncThunk("auth/createWalletApi", async (data) => {
    const response = await createWalletApi(data.postData, data.tokenData);
    return response.data;
});
export const requestKycAsync = createAsyncThunk("auth/requestKyc", async (data) => {
    const response = await requestKyc(data);
    return response.data;
});
export const checkKycAsync = createAsyncThunk("auth/checkKyc", async (data) => {
    const response = await checkKyc(data);
    return response.data;
});
export const getDocumentTypeAsync = createAsyncThunk("auth/getDocumentType", async (data) => {
    const response = await getDocumentType(data);
    return response.data;
});
export const uploadKycDocumentAsync = createAsyncThunk("auth/uploadKycDocument", async (data) => {
    const response = await uploadKycDocument(data.postData, data.tokenData);
    return response.data;
});
export const getBusinessTypesAsync = createAsyncThunk("auth/getBusinessTypes", async (data) => {
    const response = await getBusinessTypes(data);
    return response.data;
});
export const getNaicsCategoriesAsync = createAsyncThunk("auth/getNaicsCategories", async (data) => {
    const response = await getNaicsCategories(data);
    return response.data;
});
export const businessRegistrationApiAsync = createAsyncThunk("auth/businessRegistrationApi", async (data) => {
    const response = await businessRegistrationApi(data.postData, data.tokenData);
    return response.data;
});
export const businessKycApiAsync = createAsyncThunk("auth/businessKycApi", async (data) => {
    const response = await businessKycApi(data);
    return response.data;
});
export const getWalletProfileAsync = createAsyncThunk("auth/getWalletProfile", async (data) => {
    const response = await getWalletProfile(data);
    return response.data;
});
export const uploadKybDocumentAsync = createAsyncThunk("auth/uploadKybDocument", async (data) => {
    const response = await uploadKybDocument(data.postData, data.tokenData);
    return response.data;
});
export const generatePlaidTokenApiAsync = createAsyncThunk("auth/generatePlaidTokenApi", async (data) => {
    const response = await generatePlaidTokenApi(data);
    return response.data;
});
export const linkBankAccountAsync = createAsyncThunk("auth/linkBankAccount", async (data) => {
    const response = await linkBankAccount(data.postData, data.tokenData);
    return response.data;
});
export const getBankAccountApiAsync = createAsyncThunk("auth/getBankAccountApi", async (data) => {
    const response = await getBankAccountApi(data);
    return response.data;
});
export const userProfileAsync = createAsyncThunk("profile/user", async (data) => {
    const response = await getUserProfile(data);
    return response.data;
});

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.status = "idle";
            state.otp = null;
            state.emailOtp = null;
            state.emailVerified = null;
            state.country = [];
            state.district = [];
            state.state = [];
            state.city = [];
            state.vehicleMake = [];
            state.vehcileModel = [];
            state.vehicleYear = [];
            state.vehicleVins = [];
            state.driverDocument = null;
            state.register = null;
            state.uploadUrl1 = null;
            state.uploadUrl2 = null;
            state.uploadUrl3 = null;
            state.changepin = null;
            state.role = null;
            state.walletProfile = null;
            state.requestKyc = null;
            state.checkKyc = null;
            state.documentType = null;
            state.kycDocument = null;
            state.businessType = null;
            state.naicsCategory = null;
            state.businessKyc = null;
            state.kybDocument = null;
            state.generateToken = null;
            state.linkBank = null;
            state.bankDetail = null;
            toast.success("Successfully Logout")
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerOtpSendAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(registerOtpSendAsync.fulfilled, (state, { payload }) => {
                state.otp = payload
                state.status = "idle";
            })
            .addCase(registerOtpSendAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.otp = null
            })
            .addCase(registerOtpVerifyAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(registerOtpVerifyAsync.fulfilled, (state, { payload }) => {
                state.otp = payload
                state.status = "idle";
            })
            .addCase(registerOtpVerifyAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.otp = null
            })
            .addCase(loginAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(loginAsync.fulfilled, (state, { payload }) => {
                state.user = payload
                state.status = "idle";
            })
            .addCase(loginAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.user = null
            })
            .addCase(emailSendAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(emailSendAsync.fulfilled, (state, { payload }) => {
                state.emailOtp = payload
                state.status = "idle";
            })
            .addCase(emailSendAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.emailOtp = null
            })
            .addCase(emailVerifiedAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(emailVerifiedAsync.fulfilled, (state, { payload }) => {
                state.emailVerified = payload
                state.status = "idle";
            })
            .addCase(emailVerifiedAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.emailVerified = null
            })
            .addCase(countryAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(countryAsync.fulfilled, (state, { payload }) => {
                state.country = payload
                state.status = "idle";
            })
            .addCase(countryAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.country = null
            })
            .addCase(districtAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(districtAsync.fulfilled, (state, { payload }) => {
                state.district = payload
                state.status = "idle";
            })
            .addCase(districtAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.district = null
            })
            .addCase(stateAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(stateAsync.fulfilled, (state, { payload }) => {
                state.state = payload
                state.status = "idle";
            })
            .addCase(stateAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.state = null
            })
            .addCase(cityAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(cityAsync.fulfilled, (state, { payload }) => {
                state.city = payload
                state.status = "idle";
            })
            .addCase(cityAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.city = null
            })
            .addCase(registerAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(registerAsync.fulfilled, (state, { payload }) => {
                state.user = payload
                state.status = "idle";
            })
            .addCase(registerAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.user = null
            })
            .addCase(vehicleMakeAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(vehicleMakeAsync.fulfilled, (state, { payload }) => {
                state.vehicleMake = payload
                state.status = "idle";
            })
            .addCase(vehicleMakeAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.vehicleMake = null
            })
            .addCase(getUploadUrlAsync1.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getUploadUrlAsync1.fulfilled, (state, { payload }) => {
                state.uploadUrl1 = payload
                state.status = "idle";
            })
            .addCase(getUploadUrlAsync1.rejected, (state, { error }) => {
                state.status = "idle";
                state.uploadUrl1 = null
            })
            .addCase(getUploadUrlAsync2.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getUploadUrlAsync2.fulfilled, (state, { payload }) => {
                state.uploadUrl2 = payload
                state.status = "idle";
            })
            .addCase(getUploadUrlAsync2.rejected, (state, { error }) => {
                state.status = "idle";
                state.uploadUrl2 = null
            })
            .addCase(getUploadUrlAsync3.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getUploadUrlAsync3.fulfilled, (state, { payload }) => {
                state.uploadUrl3 = payload
                state.status = "idle";
            })
            .addCase(getUploadUrlAsync3.rejected, (state, { error }) => {
                state.status = "idle";
                state.uploadUrl3 = null
            })
            .addCase(driverDocumentStepAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(driverDocumentStepAsync.fulfilled, (state, { payload }) => {
                state.driverDocument = payload
                state.status = "idle";
            })
            .addCase(driverDocumentStepAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.driverDocument = null
            })
            .addCase(changePinAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(changePinAsync.fulfilled, (state, { payload }) => {
                state.changepin = payload
                state.status = "idle";
            })
            .addCase(changePinAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.changepin = null
            })
            .addCase(roleAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(roleAsync.fulfilled, (state, { payload }) => {
                state.role = payload
                state.status = "idle";
            })
            .addCase(roleAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.role = null
            })
            .addCase(createWalletAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createWalletAsync.fulfilled, (state, { payload }) => {
                state.walletProfile = payload
                state.status = "idle";
            })
            .addCase(createWalletAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.walletProfile = null
            })
            .addCase(requestKycAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(requestKycAsync.fulfilled, (state, { payload }) => {
                state.requestKyc = payload
                state.status = "idle";
            })
            .addCase(requestKycAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.requestKyc = null
            })
            .addCase(checkKycAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(checkKycAsync.fulfilled, (state, { payload }) => {
                state.checkKyc = payload
                state.status = "idle";
            })
            .addCase(checkKycAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.checkKyc = null
            })
            .addCase(getDocumentTypeAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getDocumentTypeAsync.fulfilled, (state, { payload }) => {
                state.documentType = payload
                state.status = "idle";
            })
            .addCase(getDocumentTypeAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.documentType = null
            })
            .addCase(uploadKycDocumentAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(uploadKycDocumentAsync.fulfilled, (state, { payload }) => {
                state.kycDocument = payload
                state.status = "idle";
            })
            .addCase(uploadKycDocumentAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.kycDocument = null
            })
            .addCase(getBusinessTypesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getBusinessTypesAsync.fulfilled, (state, { payload }) => {
                state.businessType = payload
                state.status = "idle";
            })
            .addCase(getBusinessTypesAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.businessType = null
            })
            .addCase(getNaicsCategoriesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getNaicsCategoriesAsync.fulfilled, (state, { payload }) => {
                state.naicsCategory = payload
                state.status = "idle";
            })
            .addCase(getNaicsCategoriesAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.naicsCategory = null
            })
            .addCase(businessRegistrationApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(businessRegistrationApiAsync.fulfilled, (state, { payload }) => {
                state.businessRegister = payload
                state.status = "idle";
            })
            .addCase(businessRegistrationApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.businessRegister = null
            })
            .addCase(getWalletProfileAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getWalletProfileAsync.fulfilled, (state, { payload }) => {
                state.walletProfile = payload
                state.status = "idle";
            })
            .addCase(getWalletProfileAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.walletProfile = null
            })
            .addCase(uploadKybDocumentAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(uploadKybDocumentAsync.fulfilled, (state, { payload }) => {
                state.kybDocument = payload
                state.status = "idle";
            })
            .addCase(uploadKybDocumentAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.kybDocument = null
            })
            .addCase(generatePlaidTokenApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(generatePlaidTokenApiAsync.fulfilled, (state, { payload }) => {
                state.generateToken = payload
                state.status = "idle";
            })
            .addCase(generatePlaidTokenApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.generateToken = null
            })
            .addCase(linkBankAccountAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(linkBankAccountAsync.fulfilled, (state, { payload }) => {
                state.linkBank = payload
                state.status = "idle";
            })
            .addCase(linkBankAccountAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.linkBank = null
            })
            .addCase(getBankAccountApiAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getBankAccountApiAsync.fulfilled, (state, { payload }) => {
                state.bankDetail = payload
                state.status = "idle";
            })
            .addCase(getBankAccountApiAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.bankDetail = null
            })
            .addCase(userProfileAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(userProfileAsync.fulfilled, (state, { payload }) => {
                state.userProfile = payload
                state.status = "idle";
            })
            .addCase(userProfileAsync.rejected, (state, { error }) => {
                state.status = "idle";
                state.userProfile = null
            })
    }
});

export const { logout } = authSlice.actions;

export const selectLoginAuth = (state) => state.auth.user;
export const selectLoginProfile = (state) => state.auth.user;
export const selectRegisterAuth = (state) => state.auth.register;
export const selectOtpId = (state) => state.auth.otp;
export const selectEmailOtpId = (state) => state.auth.emailOtp;
export const selectPhoneVerify = (state) => state.auth.otp;
export const selectCountry = (state) => state.auth.country;
export const selectDistrict = (state) => state.auth.district;
export const selectState = (state) => state.auth.state;
export const selectCity = (state) => state.auth.city;
export const selectUploadedUrl1 = (state) => state.auth.uploadUrl1;
export const selectUploadedUrl2 = (state) => state.auth.uploadUrl3;
export const selectUploadedUrl3 = (state) => state.auth.uploadUrl3;
export const selectEmailVerified = (state) => state.auth.emailVerified;
export const selectChangePin = (state) => state.auth.changepin;
export const selectRoleStep = (state) => state.auth.role
export const securityPinCheck = (state) => state.auth.securityPin
export const selectWalletProfile = (state) => state.auth.walletProfile
export const selectKycStatus = (state) => state.auth.checkKyc
export const selectDocumentType = (state) => state.auth.documentType
export const selectBusinessType = (state) => state.auth.businessType
export const selectNaicsCategory = (state) => state.auth.naicsCategory
export const selectUserProfile = (state) => state.auth.userProfile;

export default authSlice.reducer;