import React, { useState } from 'react'
import { usePaymentInputs } from 'react-payment-inputs';

const AddCreditCard = (props) => {
    const [saveCard, setSaveCard] = useState("")
    const [cardNumber, setCardNumber] = useState("")
    const [cardHolderName, setCardHolderName] = useState("")
    const [cardCVV, setCardCvv] = useState("")
    const [expiryDate, setExpiryDate] = useState();

    const {getExpiryDateProps} = usePaymentInputs();

    const handleChangeExpiryDate = (e) => {
        const value = e.target.value
        setExpiryDate(value);
    }
    const setNumber = e => {
        let { value } = e.target;
        value = value.replace(/ /gi, "");
        if (isNaN(value)) {
            return;
        } else {
            setCardNumber(e.target.value)
        }
    };
    const setName = e => {
        const cardHolderName = e.target.value.toUpperCase();
        setCardHolderName(cardHolderName);
    };

    const setCVV = e => {
        let { value } = e.target;
        value = value.replace(/ /gi, "");
        if (isNaN(value)) {
            return;
        } else {
            setCardCvv(e.target.value);
        }
    };
    const formatCardNumber = value => {
        let v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
        let matches = v.match(/\d{4,16}/g);
        let match = (matches && matches[0]) || "";
        let parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(" ");
        } else {
            return value;
        }
    };

    return (
        <div className="modal-content wallet-modalcontent">
            <div className="modal-header">
                <button
                    type="button"
                    className="btn-close"
                    onClick={props?.close}
                >
                    <span aria-hidden="true">close</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="modal-mainprt wallet-modal">
                    <div className="wallet-mdlhead">
                        <h2>Add credit card</h2>
                    </div>
                    <div className="payment-mdlmain">
                        <div className="creditcard-mainmdl">
                            <form action="#" method="POST">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="creditcard-itemmdl">
                                            <label htmlFor="c1">Card Number</label>
                                            <input
                                                id="c1"
                                                maxLength={19}
                                                placeholder="Card number"
                                                value={formatCardNumber(cardNumber)}
                                                options={{ creditCard: true }}
                                                onChange={setNumber}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="creditcard-itemmdl">
                                            <label htmlFor="c2">Expiry</label>
                                            <input type="date" id="c2" placeholder="mm/yy"
                                        {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiryDate} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="creditcard-itemmdl">
                                            <label htmlFor="c3">CVV</label>
                                            <input
                                                id="c3"
                                                maxLength={3}
                                                placeholder="Enter CVC"
                                                value={cardCVV}
                                                options={{
                                                    numeral: true
                                                }}
                                                onChange={setCVV}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="creditcard-itemmdl">
                                            <label htmlFor="c4">Card Holder Name</label>
                                            <input
                                                type="text"
                                                id="c4"
                                                placeholder="Card Holder name"
                                                maxLength="30"
                                                value={cardHolderName}
                                                onChange={e => setName(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="creditcard-itemmdl">
                                            <div className="form-group">
                                                <input type="checkbox" id="check" value="savecard" onChange={(e) => setSaveCard(e.target.value)} />
                                                <label htmlFor="check">
                                                    Save this card for a faster checkout
                                                </label>
                                            </div>
                                            <p>
                                                By saving your card you grant us your consent to
                                                stor your payment method for future order. You can
                                                withdraw anytime.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="creditcard-itemmdl">
                                            <button
                                                type="button"
                                                onClick={() => { props.details('payment_method') }}
                                            >
                                                Add Card
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCreditCard