import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import Flat from "../../public/images/flat.png";
import Visa from "../../public/images/visa.png";
import { NumericFormat } from 'react-number-format';
import { getBankAccountApiAsync, selectLoginAuth } from '../auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { redeemSilaApiAsync } from './dashbaordSlice';

const InstantTransferModal = (props) => {
    const [amount, setAmount] = useState("")
    const [loading, setLoading] = useState(false)
    const [tranferLoader, setTransferLoader] = useState(false)
    const [selectedBank, setSelectedBank] = useState("")
    const [bankList, setBankList] = useState([])
    const dispatch = useDispatch()
    const auth = useSelector(selectLoginAuth)
    const handleAmountChange = (data) => {
        setAmount(Number(data.replace("$", "")))
    }

    const getBankAccountList = () => {
        setLoading(true)
        dispatch(getBankAccountApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then(async (obj) => {
                setBankList(obj?.payload)
                setSelectedBank(obj?.payload[0]?.account_name)
                setLoading(false)
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }
    console.log(amount, "amount")
    console.log(bankList, "bank list")

    const tranferSilaToWallet = () => {
        setTransferLoader(true)
        let params = {
            postData: {
                "amount": amount,
                "account_name": bankList[0]?.account_name
            },
            tokenData: auth?.payload?.token
        }
        dispatch(redeemSilaApiAsync(params))
            .then(unwrapResult)
            .then((obj) => {
                setTransferLoader(false)
                props.getWallet()
                props.getTransaction()
                props.close()
            }
            )
            .catch((obj) => {
                setTransferLoader(false)
            })
    }

    useEffect(() => {
        getBankAccountList()
    }, [])

    return (
        <>
            {
                loading ?
                    <div className="pageLoader d-flex justify-content-center align-items-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className="modal-content wallet-modalcontent fullwidth_modal">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                onClick={props.close}
                            >
                                <span aria-hidden="true">close</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-mainprt wallet-modal">
                                <div className="wallet-mdlhead">
                                    <h2>Transfer Balance</h2>
                                </div>

                                <div className="wallet-mdlupper">
                                    {/* <h2>
                            <sup>$</sup>
                        </h2> */}
                                    <NumericFormat value={amount} prefix={'$'} style={{ border: "none" }} placeholder='$0' onChange={(e) => handleAmountChange(e.target.value)} />
                                    <p>Your balance : JBR {props?.walletData}</p>
                                    <Link
                                        to="#"
                                        onClick={() => { props.details('normal_transfer') }}
                                    >
                                        <img src={Flat} alt="" />
                                        <span className="instant-transcnt">
                                            <h4>Instant Transfer</h4>
                                            <p>
                                                2% fee (Max $
                                                100 in Fees)
                                            </p>
                                        </span>
                                        <span>
                                            <i className="fas fa-chevron-right"></i>
                                        </span>
                                    </Link>
                                </div>

                                <div className="transfer-mdlitem">
                                    <ul>
                                        <li>
                                            Fee <span>$0.0</span>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <strong>Transfer to</strong>{" "}
                                                <span>
                                                    {/* <img src={Visa} alt="" />  */}
                                                    {bankList[0]?.account_name ? `${(bankList[0]?.account_name).slice(0, 20)}...` : ""}{" "}
                                                    <i className="fas fa-chevron-right"></i>
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s, when an unknown
                                        printer took a galley of type
                                    </p>
                                </div>
                                <div className="trans-mdlbtn">
                                    <button className="btn btn-primary continue" disabled={tranferLoader === true} onClick={() => { tranferSilaToWallet() }}>
                                        {tranferLoader === true && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        &nbsp;&nbsp;
                                        Transfer ${amount ? amount.toFixed(2) : "0.00"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>

    )
}

export default InstantTransferModal