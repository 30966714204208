import React, { useState } from 'react'
import BankFilled from "../../public/images/bank-filled.svg";
import DebitCard from "../../public/images/debit-card.svg";
import CreditCard from "../../public/images/credit-card.svg";
import { Link, useHistory } from 'react-router-dom';

const PaymentMethod = (props) => {
    const [saveCard, setSaveCard] = useState("creditcard")
    const history = useHistory()
    return (
        <div className="modal-content wallet-modalcontent">
            <div className="modal-header">
                <button
                    type="button"
                    className="btn-close"
                    onClick={props?.close}
                >
                    <span aria-hidden="true">close</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="modal-mainprt wallet-modal">
                    <div className="wallet-mdlhead">
                        <h2>Payment methods</h2>
                    </div>

                    <div className="payment-mdlmain">
                        <ul>
                            <li>
                                <Link to="#">
                                    <span>
                                        <img src={CreditCard} alt="" /> Credit card
                                        **********5698
                                    </span>
                                    <input type="radio" name="radio" value="creditcard" checked={saveCard === "creditcard"} onChange={(e) => setSaveCard(e.target.value)} />
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <span>
                                        <img src={DebitCard} alt="" /> Add debit card
                                    </span>{" "}
                                    <i className="fas fa-chevron-right"></i>
                                </Link>
                            </li>
                            <li>
                                <Link to="#">
                                    <span>
                                        <img src={BankFilled} alt="" /> Connect your bank
                                        account
                                    </span>{" "}
                                    <i className="fas fa-chevron-right"></i>
                                </Link>
                            </li>
                        </ul>
                        <div className="setbtn-mdlp mt-auto">
                            <Link to="/dashboard/wallet" onClick={props.close}>Set for Transfer</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethod