import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Location from "../../../../public/images/track-location-2.png";
import Circle from "../../../../public/images/circle-3.png";
import Order from "../../../../public/images/order-prof.png";
import Tell from "../../../../public/images/tell.svg";
import Chat from "../../../../public/images/chat.svg";
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryDetailsAsync, selectDeliveryData } from "../../deliverySlice"

const WaitingOnDoor = (props) => {
  const history = useHistory();

  const dispatch = useDispatch()
  const deliveryData = useSelector(selectDeliveryData)

  useEffect(() => {
    dispatch(getDeliveryDetailsAsync())
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
            width="573"
            height="700"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />{" "}
        </div>
        <div className="reqaccpt-exprs text-center hwd-uprbtn">
        <span>
          1 hour window delivery accepted: <strong>1</strong>
        </span>
        </div>

        <div className="track-lcitem track-lctdl3">
          <img src={Location} alt="" />
        </div>

        <div className="pickup-pointbox pointbox-blue2">
          <div className="accept-mdlupper">
            <h2>
              Waiting on door step<span>{deliveryData?.id}</span>
            </h2>
            <img src={Circle} alt="" />
          </div>

          <div className="accept-mdltimline">
            <div className="order-prt">
              <img src={Order} alt="" />
              <div className="order-cnt">
                <h2>{deliveryData?.recipientName}</h2>
                <p>{deliveryData?.deliveryAddress}</p>
                <ul>
                  <li>
                    <Link to="#">
                      <img src={Tell} alt="" /> Call
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push("/dashboard/delivery/message", {
                          fromWaitingOnDoor: true,
                        });
                      }}
                    >
                      <img src={Chat} alt="" /> Message
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="pickup-mbtn">
            <Link to='/dashboard/one-hour-delivery/delivery/verify-delivery'> Mark as delivered</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingOnDoor;

// <div className="reqaccpt-exprs text-center hwd-uprbtn">
// <span>
//   1 hour window delivery accepted: <strong>1</strong>
// </span>
// </div>