import React, { useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import LeftArrow from "../../../public/images/left-arrow.svg";
import { toast } from "react-toastify";
import { driverDocumentStepAsync, selectLoginAuth } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const RegistrationGuideline = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const gotoRegisterPage = (e) => {
    e.preventDefault();
    history.push('/register')
  }
  let data = {
    "postData": {
      "driver_steps": "1",
      "guideline_flag": "Y"
    },
    "tokenData": auth?.payload?.token
  }
  const gotoRegister2Page = async (e) => {
    e.preventDefault();
    await dispatch(driverDocumentStepAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/register2')
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
    
  }

  useEffect(() => { }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="registration-upper reg-heading2">
          <h4><Link to="#" onClick={(e) => gotoRegisterPage(e)}><img src={LeftArrow} alt="" /></Link> Guidelines to follow</h4>
        </div>

        <div className="guidline-main">
          <h2>Welcome to JOBR!</h2>
          <p>You will need to fill out a form in the next step. Please read the instructions below to complete the form correctly</p>
          <div className="guidline-inner">
            <h4>Current address</h4>
            <p>Enter your current address here, not a permanent address. Address should be written in detail (house number, road number, lane / sector, area name).</p>
            <h4>Driver's license</h4>
            <p>This will speed up the approval process when we need to cross-check your license with other documents.</p>
            <h4>Vehicle insurance and registration</h4>
            <p>This will speed up the approval process when we need to cross-check your license with other documents.</p>
            <h4>Photo</h4>
            <p>Upload your recent photographs</p>
          </div>
          <div className="guidline-btmcnt">
            <h4>Ensure that your document contains all required details before uploading. The entire document should be clearly visible.</h4>
          </div>
        </div>

        <div className="guidline-btn mt-auto">
          <Link to="#" onClick={(e) => gotoRegister2Page(e)}>I will follow the guidelines</Link>
        </div>
      </main>
    </>
  );
};

export default RegistrationGuideline;