import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectLoginAuth } from "../auth/authSlice";
import Checklist from "./checklist";


const Dashboard = (props) => {
  const auth = useSelector(selectLoginAuth)
  useEffect(() => {
    // 
  }, [])

  return (
    <>
      <Checklist />
    </>
  );
};

export default Dashboard;
