import React, { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { businessKycApiAsync, selectLoginAuth } from '../authSlice';
import { useHistory } from 'react-router-dom';

const KybVerification = () => {
    const history = useHistory()
    const auth = useSelector(selectLoginAuth)
    const [kybStatus, setKybStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const handleKyb = async () => {
        setLoading(true)
        await dispatch(businessKycApiAsync(auth?.payload?.token))
            .then(unwrapResult)
            .then((obj) => {
                setLoading(false)
                setKybStatus(true)
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(obj?.msg)
                }
            }
            )
            .catch((obj) => {
                setLoading(false)
            })
    }

    return (
        <>
            <main className="main-wrapper kycwrapper">
                <div className="registration-upper reg-heading2">
                    <h4>
                        Request KYB
                    </h4>
                </div>
                <div className='commontMainbox'>
                    <p className='parasubHead'>
                        We must verify that all users of the Sila platform are who they say they are. We do this by submitting end-user information for KYC review by our Identity Verification Partner. The user will not be able to transact until the user is verified. With great power comes great responsibility.</p>
                    <div className='requestbtn'>
                        <button type='button' className='blueprimeBtn' onClick={handleKyb} disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            Request KYB   </button>
                    </div>
                </div>
                <div className='bottomBtnbox'>
                    <button type="submit" className="document_button blueprimeBtn" disabled={loading || !kybStatus} onClick={() => history.push('/business-id-verification')}>
                        {loading === true && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        Continue</button>
                </div>
            </main>
        </>
    )
}

export default KybVerification